import { ReactNode } from 'react'

import { FooterLinks } from './FooterLinks'

export const Footer = ({ children }: { children?: ReactNode }) => {
    return (
        <footer>
            {children && <>{children}</>}
            <FooterLinks />
        </footer>
    )
}
