import styled, { css } from 'styled-components'

import { Wrapper } from '@rg/patternlab'

export const FooterLinksWrapper = styled(Wrapper)(
    ({ theme }) => css`
        display: flex;
        gap: ${theme.space.ml};
        padding: ${theme.space.md};
    `
)
