import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Wrapper } from '@rg/patternlab'

export const StyledWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        padding-left: ${theme.space.xs};

        ${theme.mediaQueries.firefox} {
            margin-bottom: ${theme.space.xs};
        }
    `
)

export const StepLabel = styled(Wrapper)(
    () => css`
        width: 33.3%;
        color: ${onboardingStyles.colors.darkBlue};
    `
)
