import { useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { ColorKeys } from 'styles/onboardingStyles/onboardingStyles.types'

import { Icon, Wrapper } from '@rg/patternlab'

import { StyledLink } from './IconButton.styles'
import { IconButtonProps } from './IconButton.types'

export const IconButton = ({
    children,
    onClick,
    loading = false,
    buttonMargin,
    color = onboardingStyles.colors.yellow as ColorKeys,
    iconName,
    iconSize = 22,
    testId,
}: IconButtonProps) => {
    const theme = useContext(ThemeContext)
    return (
        <StyledLink
            color={color}
            type="button"
            onClick={onClick}
            loading={loading}
            m={buttonMargin ?? theme.space.none}
            {...(testId && { 'data-testid': testId })}
        >
            <Icon
                stroke
                icon={iconName}
                color={color}
                size={iconSize}
                margin={`${theme.space.none} ${theme.space.sm} ${theme.space.none} ${theme.space.none}`}
            />
            <Wrapper color={color}>{children}</Wrapper>
        </StyledLink>
    )
}
