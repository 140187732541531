import { Input } from '@rg/patternlab'

import { InnerWrapper, InputAppend, MainWrapper, StyledFieldMessage } from './FormInput.styles'
import { FormInputProps } from './FormInput.types'

export const FormInput = ({
    type,
    name,
    label,
    value,
    disabled,
    append,
    short = false,
    errorTestId = '',
    formikTouched,
    formikErrors,
    onChange,
    onBlur,
    ...props
}: FormInputProps) => {
    return (
        <MainWrapper short={short} hasError={Boolean(formikTouched && formikErrors)}>
            <InnerWrapper append={Boolean(append)}>
                <Input
                    type={type}
                    name={name}
                    label={label}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...props}
                />
                {Boolean(append) && <InputAppend>{append}</InputAppend>}
            </InnerWrapper>
            {formikTouched && (
                <StyledFieldMessage data-testid={errorTestId} status="error">
                    {formikErrors}
                </StyledFieldMessage>
            )}
        </MainWrapper>
    )
}
