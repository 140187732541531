import styled, { css } from 'styled-components'

import { Link } from '@rg/patternlab'
import { LinkComponentProps } from '@rg/patternlab/components/Link/Link.types'

export const StyledLink = styled(Link)<LinkComponentProps & { color?: string }>(
    ({ color }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        cursor: pointer;
        color: ${color};

        &:hover,
        &:focus {
            color: ${color};
        }

        svg {
            position: unset;
            stroke-linecap: square;
            stroke-linejoin: miter;
            overflow: visible;
            stroke-width: 0.7;
        }
    `
)
