/**
 * Get value from url query parameter (eg: get value of foo from example.com?foo=1)
 * Returns the value as a string or undefined if param is not found
 *
 * @param str param which you want to get the value from (eg: foo)
 * @param search search params (eg: ?foo=1)
 *
 * todo: replace with URLSearchParams() (and a polyfill for IE11)
 */

export const getUrlParam = (str: string, search: string): string | undefined => {
    const name = str.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    var results = regex.exec(search)
    return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

/**
 * Converts a value from string to boolean. Useful when working with query strings.
 * Note: this will convert "invalid" strings to false.
 */
export const stringToBoolean = (value: string): boolean => ['true', '1'].includes(value.toLowerCase())
