import { t } from '@transifex/native'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

export const hex2rgba = (hex: string, alpha: number): string => {
    const r = parseInt(hex.substring(1, 3), 16)
    const g = parseInt(hex.substring(3, 5), 16)
    const b = parseInt(hex.substring(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const hexToRGBA = (hex: string, opacity: number) => {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return `rgba(${r},${g},${b},${opacity})`
}

export const getStyledInputBorder = (hasError?: boolean, disabled?: boolean) => {
    if (hasError) return onboardingStyles.colors.red

    return disabled ? hexToRGBA(onboardingStyles.colors.lighterBlue, 0.5) : onboardingStyles.colors.lighterBlue
}

export const getStyledInputBackgroundColor = (defaultColor: string, hasError?: boolean, disabled?: boolean) => {
    if (hasError) return onboardingStyles.colors.lighterRed

    return disabled ? hexToRGBA(defaultColor, 0.5) : defaultColor
}

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms))

export const getUrlParams = (str: string): string => {
    const name = str.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = regex.exec(window.location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const clearUrlParam = (param: string): void => {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
    window.history.replaceState(null, '', url.href)
}

export const clearUrlParams = () => window.history.replaceState(null, '', window.location.pathname)

export const combineRegex = (r1: string, r2: string) => {
    return new RegExp(`${new RegExp(r1).source}|${new RegExp(r2).source}`)
}

export const removeNullEntries = (obj: { [key: string]: any }) =>
    Object.entries(obj)
        .filter(([_, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})

export const addLeadingZeroIfMissing = (str: string) => (str.charAt(0) === '0' ? str : `0${str}`)
export const removeLeadingZero = (str: string) => (str?.charAt(0) === '0' ? str?.substring(1) : str)

export const clearLocalStorageVars = () => {
    localStorage.removeItem('onboardingId')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('stepReached')
    localStorage.removeItem('plan')
    localStorage.removeItem('seatCount')
}

/**
 * Pick out matching keys of object
 *
 * @param obj - object of properties
 * @param keys - array of keys to pick out of the object
 * @returns {object} - an object of matching keys only
 */
export function pick(obj: Record<string, any>, keys: string[]): Record<string, any> {
    return keys.map((k) => (k in obj ? { [k]: obj[k] } : {})).reduce((res, o) => Object.assign(res, o), {})
}

/**
 * Filters matching keys out of object
 *
 * @param obj - object of properties
 * @param keys - array of keys to pick out of the object
 * @returns {object} - an object without matching keys
 */
export function reject(obj: Record<string, any>, keys: string[]): Record<string, any> {
    const vkeys = Object.keys(obj).filter((k) => !keys.includes(k))

    return pick(obj, vkeys)
}

export const formatDate = (date: Date): string =>
    `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`
export const formatDateIntlGB = (date: string): string => new Intl.DateTimeFormat('en-GB').format(new Date(date))

export const addOneYear = (date: Date): Date => {
    const newDate: Date = new Date(date)
    return new Date(newDate.setFullYear(newDate.getFullYear() + 1))
}

export const addDays = (date: Date, days: number): Date => {
    const newDate: Date = new Date(date)
    return new Date(newDate.setDate(newDate.getDate() + days))
}

export const addMonths = (date: Date, monthsToBeadded: number): Date => {
    const newDate: Date = new Date(date)
    return new Date(newDate.setMonth(newDate.getMonth() + monthsToBeadded))
}

export const minusOneDay = (date: Date): Date => {
    const newDate: Date = new Date(date)
    return new Date(newDate.setDate(newDate.getDate() - 1))
}

interface LabelledObject {
    label: string
}
export const byLabelAscending = (a: LabelledObject, b: LabelledObject) => a.label.localeCompare(b.label)

export const stripOutHTML = (str: string) => str.replace(/<[^>]*>?/gm, '')

export const getPeriodText = (discountPeriod: number | undefined) => {
    if (!discountPeriod) return ''
    if (discountPeriod % 12 === 0) {
        const years = discountPeriod / 12
        return `${years} ${years === 1 ? t('year') : t('years')}`
    } else {
        const months = discountPeriod
        return `${months} ${months === 1 ? t('month') : t('months')}`
    }
}

export const isJSONString = (str: string): boolean => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const toLowercaseWithDashes = (str: string) => str.toLowerCase().replace(/\s+/g, '-')
