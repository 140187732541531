import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import { restLink } from './apolloLinks/restLink'
import { errorLink } from './apolloLinks/errorLink'
import { schema } from './apolloLinks/schemaLink'
import { authLink } from './apolloLinks/authLink'
import { resolvers } from './resolvers'

export const cache = new InMemoryCache()

export const client = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([errorLink, authLink, restLink, schema]),
    cache,
    resolvers,
    defaultOptions: {
        query: {
            errorPolicy: 'all',
        },
    },
})
