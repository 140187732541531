import 'reactjs-popup/dist/index.css'

import { H2, Icon, Wrapper } from 'components/ui'

import { useTheme } from '@rg/common'

import { CloseModalHandler, ModalProps } from './Modal.types'
import { CloseButton, ModalContainer, StyledPopup } from './Modal.styles'

export const Modal = ({ children, trigger, title }: ModalProps) => {
    const theme = useTheme()

    return (
        <StyledPopup trigger={trigger} modal position="center center" lockScroll closeOnDocumentClick>
            {(close: CloseModalHandler) => (
                <ModalContainer>
                    <Wrapper
                        display="flex"
                        justifyContent={title ? 'space-between' : 'end'}
                        alignItems="flex-start"
                        mb={theme.space.lg}
                    >
                        {title && <H2 m={0}>{title}</H2>}
                        <CloseButton aria-label="Close modal" data-testid="modal-close" onClick={close} type="button">
                            <Icon name="delete" />
                            {/* &times; */}
                        </CloseButton>
                    </Wrapper>
                    {children && typeof children === 'function' ? children(close) : children}
                </ModalContainer>
            )}
        </StyledPopup>
    )
}
