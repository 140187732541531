import styled, { css } from 'styled-components'

import { Wrapper } from '@rg/patternlab'

export const StampWrapper = styled(Wrapper)<{ isPricingPage: boolean }>(
    ({ theme, isPricingPage }) => css`
        position: relative;
        width: max-content;
        height: max-content;
        text-align: center;
        bottom: ${isPricingPage ? '50px' : theme.space.none};
        right: ${isPricingPage ? theme.space.md : theme.space.none};

        ${theme.mediaQueries.sm} {
            bottom: ${isPricingPage ? theme.space.lg : theme.space.none};
            right: ${isPricingPage ? theme.space.md : theme.space.none};
        }

        ${theme.mediaQueries.md} {
            bottom: ${isPricingPage ? theme.space.xs : theme.space.none};
            right: ${isPricingPage ? theme.space.sm : theme.space.none};
        }

        ${theme.mediaQueries.lg} {
            bottom: ${isPricingPage ? theme.space.xs : theme.space.none};
            right: ${isPricingPage ? theme.space.sm : theme.space.none};
        }
    `
)

export const StampImage = styled.img(
    () => css`
        filter: drop-shadow(0 2px 5px rgb(0 0 0 / 15%));
        width: 170px;
        height: 170px;
    `
)
