import { createContext, useContext } from 'react'

import { FeatureFlagsProviderProps, FeatureFlagsState } from './FeatureFlags.types'
import { getUrlParam, stringToBoolean } from './FeatureFlags.utils'

/**
 * Takes a FeatureFlagsState object (with default values) and a url query string from a URL.
 * Returns a FeatureFlagsState object with the values from the url query string, if they're defined, otherwise the defaults.
 */
export const overrideFlagsFromQueryString = (flags: FeatureFlagsState, query: string): FeatureFlagsState =>
    Object.entries(flags).reduce((acc, [key, defaultValue]) => {
        key = key.toLowerCase()
        const paramValue = getUrlParam(key.toLowerCase(), query.toLowerCase())
        return {
            [key]: paramValue === undefined ? defaultValue : stringToBoolean(paramValue),
            ...acc,
        }
    }, {})

let FeatureFlagsContext: React.Context<FeatureFlagsState>

/**
 * Initializes the FeatureFlags state from an object while overriding default values from current url query string, creates FeatureFlags Context and returns Provider
 */
export const FeatureFlagsProvider = ({ flags, children }: FeatureFlagsProviderProps) => {
    const initialState: FeatureFlagsState = overrideFlagsFromQueryString(flags, window.location.search)
    FeatureFlagsContext = createContext<FeatureFlagsState>(initialState)
    return <FeatureFlagsContext.Provider value={initialState}>{children}</FeatureFlagsContext.Provider>
}

export const useFeatureFlags = () => {
    const context = useContext(FeatureFlagsContext)
    if (context === undefined) {
        throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider')
    }
    return context
}
