import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useLanguages, useTX } from '@transifex/react'
import { isEnvironmentProduction } from 'index.constants'

import { Language, LanguageContextType } from './language.types'
import { getInitialLanguage, getRecaptchaLanguage } from './languageHelpers'

export const DEFAULT_TX_LANGUAGE: Language = {
    code: 'en_GB',
    name: 'English (United Kingdom)',
    localized_name: 'English (UK)',
    rtl: false,
}

const initialValue: LanguageContextType = {
    language: DEFAULT_TX_LANGUAGE,
    loadingLanguage: false,
    recaptchaLanguage: getRecaptchaLanguage(DEFAULT_TX_LANGUAGE.code),
    txLanguages: [],
    handleSaveLanguage: () => {},
}

const allowedProdLanguageNames = ['English (Australia)', 'English (Ireland)', 'English (United Kingdom)']

export const LanguageContext = createContext<LanguageContextType>(initialValue)

export const LanguageContextProvider = ({ children }: { children: ReactNode }) => {
    const tx = useTX()
    const txLanguages: Language[] = useLanguages()

    // there is {code: 'en', name: 'English', localized_name: 'English', rtl: false} in txLanguages, which is not needed
    // and cannot be removed from Transifex, because it's selected as source language, hence the filter below
    const filteredTxLanguages = useMemo(
        () =>
            txLanguages.filter((language: Language) => {
                //removes source English
                if (language.name !== 'English') {
                    if (isEnvironmentProduction) {
                        return allowedProdLanguageNames.includes(language.name)
                    } else {
                        return language
                    }
                }
                return false
            }),
        [txLanguages]
    )

    const [language, setLanguage] = useState<Language>({} as Language)
    const [loadingLanguage, setLoadingLanguage] = useState<boolean>(true)
    const [recaptchaLanguage, setRecaptchaLanguage] = useState('en-GB')

    const handleSaveLanguage = useCallback(
        (language: Language) => {
            setLanguage(language)
            tx.setCurrentLocale(language.code)
        },
        [tx]
    )

    useEffect(() => {
        if (filteredTxLanguages.length) {
            const language = getInitialLanguage(filteredTxLanguages)
            setRecaptchaLanguage(getRecaptchaLanguage(language.code))
            handleSaveLanguage(language)
            setLoadingLanguage(false)
        }
    }, [handleSaveLanguage, filteredTxLanguages, txLanguages])

    const contextValue: LanguageContextType = useMemo(
        () => ({
            language,
            loadingLanguage,
            recaptchaLanguage,
            txLanguages: filteredTxLanguages,
            handleSaveLanguage,
        }),
        [language, recaptchaLanguage, filteredTxLanguages, handleSaveLanguage]
    )

    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>
}

export const useLanguageContext = (): LanguageContextType => {
    const context = useContext(LanguageContext)
    if (typeof context === 'undefined') {
        throw new Error('useLanguageContext must be used within a LanguageContextProvider')
    }
    return context
}
