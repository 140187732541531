import { useT } from '@transifex/react'

import { Wrapper } from '@rg/patternlab'

import { ForeverFreeStampText } from './ForeverFreeStamp.styles'
import { StampWithRibbon } from './components/StampWithRibbon'

export const ForeverFreeStamp = ({ ribbonText }: { ribbonText?: string }) => {
    const t = useT()

    return (
        <Wrapper data-testid="forever-free-stamp">
            <StampWithRibbon {...{ ribbonText }}>
                <ForeverFreeStampText>
                    <span>{t('100%')}</span>
                    <span>{t('Free')}</span>
                </ForeverFreeStampText>
            </StampWithRibbon>
        </Wrapper>
    )
}
