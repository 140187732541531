import { useT } from '@transifex/react'
import { getPeriodText } from 'utils/helpers'

import { Wrapper } from '@rg/patternlab'

import { StampWithRibbon } from './components/StampWithRibbon'
import { ExtendedFreetrialStampText } from './ExtendedFreetrialStamp.styles'

export const ExtendedFreetrialStamp = ({
    freeTrialPeriod,
    ribbonText,
}: {
    freeTrialPeriod: number
    ribbonText?: string
}) => {
    const t = useT()
    const freeTrialText = getPeriodText(freeTrialPeriod)

    return (
        <Wrapper data-testid="free-month-stamp">
            <StampWithRibbon {...{ ribbonText }}>
                <ExtendedFreetrialStampText>
                    <span>{freeTrialText}</span>
                    <span>{t(' Free ')}</span>
                    <span>{t('trial')}</span>
                </ExtendedFreetrialStampText>
            </StampWithRibbon>
        </Wrapper>
    )
}
