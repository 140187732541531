import styled, { css } from 'styled-components'
import {
    DisplayProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    compose,
    display,
    flexbox,
    layout,
    space,
} from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Icon } from '@rg/patternlab'

export const IconWrapper = styled.span<DisplayProps & FlexboxProps & LayoutProps & SpaceProps>`
    display: flex;
    align-items: center;
    flex: none;

    ${compose(display, flexbox, layout, space)}
`

export const BigIcon = styled(Icon)(
    ({ theme }) => css`
        width: ${theme.space.xl};
        height: ${theme.space.xl};
        margin-top: ${theme.space.ml};
        color: ${onboardingStyles.colors.lightPurple};

        path {
            fill: ${onboardingStyles.colors.lightPurple};
        }
    `
)
