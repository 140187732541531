import styled, { css } from 'styled-components'
import {
    BorderProps,
    ColorProps,
    FlexboxProps,
    FontSizeProps,
    FontWeightProps,
    LayoutProps,
    SpaceProps,
    TextAlignProps,
    TypographyProps,
    border,
    color,
    compose,
    flexbox,
    fontSize,
    fontWeight,
    layout,
    space,
    textAlign,
    typography,
} from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

export const P = styled.p<ColorProps & SpaceProps & TypographyProps>(
    ({ theme }) => css`
        margin: ${theme.space.none} ${theme.space.none} ${theme.space.md} ${theme.space.none};

        && {
            ${compose(color, space, typography)}
        }
    `
)

export const LineThroughP = styled.p<
    LayoutProps & SpaceProps & FlexboxProps & FontSizeProps & FontWeightProps & TextAlignProps & ColorProps
>(
    () => css`
        text-decoration: line-through;

        ${compose(space, flexbox, layout, space, color, fontSize, fontWeight, textAlign)}
    `
)

export const H1 = styled.h1<ColorProps & SpaceProps & TypographyProps & FlexboxProps>(
    ({ theme }) => css`
        color: ${onboardingStyles.colors.darkBlue};
        font-size: ${theme.fontSizes.xxl};
        line-height: 2rem;
        font-weight: 700;
        margin: 20px ${theme.space.none};
        padding-bottom: ${theme.space.sm};
        border-bottom: 1px solid ${theme.palette.greyscale.main['30']};

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.xxxxl};
            line-height: 2.75rem;
            margin: 35px ${theme.space.none} 25px;
            padding-bottom: 10px;
        }

        && {
            ${compose(color, space, typography, flexbox)}
        }
    `
)

export const H2 = styled.h2<ColorProps & SpaceProps & TypographyProps>(
    ({ theme }) => css`
        color: ${onboardingStyles.colors.darkBlue};
        font-size: ${theme.fontSizes.lg};
        font-weight: 600;
        margin: 25px ${theme.space.none} 15px;

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.xl};
            margin: 35px ${theme.space.none} 20px;
        }

        && {
            ${compose(color, space, typography)}
        }
    `
)

export const H3 = styled.h2<ColorProps & SpaceProps & TypographyProps>(
    ({ theme }) => css`
        color: ${onboardingStyles.colors.darkBlue};
        font-size: ${theme.fontSizes.sm};
        font-weight: 400;
        margin: 20px ${theme.space.none} 10px;

        ${theme.mediaQueries.sm} {
            font-size: ${theme.fontSizes.md};
            margin: 15px ${theme.space.none} 10px;
        }

        && {
            ${compose(color, space, typography)}
        }
    `
)

export const LeadText = styled.p<ColorProps & SpaceProps & TypographyProps>(
    ({ theme }) => css`
        font-size: ${theme.fontSizes.lg};

        ${theme.mediaQueries.sm} {
            font-size: ${theme.fontSizes.xl};
            margin: 35px ${theme.space.none} 20px;
        }

        && {
            ${compose(color, space, typography)}
        }
    `
)

export const UnstyledList = styled.ul(
    ({ theme }) => css`
        padding-left: ${theme.space.none};
        list-style-type: none;
    `
)

export const HR = styled.hr<BorderProps & SpaceProps>(
    ({ theme }) => css`
        border-style: solid;
        margin: ${theme.space.lg} ${theme.space.none};
        border-top-color: ${theme.palette.greyscale.main['40']};
        border-bottom-width: 0;

        ${compose(space, border)}
    `
)
