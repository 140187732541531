import styled, { css } from 'styled-components'
import { width } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { FixedImageBackgroundProps, GradientBackgroundProps } from './Background.types'

// this element should not wrap another element otherwise window won't be scrollable (it has height: 100vh). improve this
export const FixedImageBackground = styled.div<FixedImageBackgroundProps>(
    ({ theme, src }) => css`
        ${theme.mediaQueries.lg} {
            width: 100%;
            position: fixed;
            z-index: -100;
            background-image: url(${src});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            height: 100vh;
        }
    `
)

export const GradientBackground = styled.div<GradientBackgroundProps>(
    ({ height = 300, paddingTop = 0 }) => css`
        width: 100%;
        position: relative;
        z-index: -100;
        padding-top: ${paddingTop}px;
        background: ${`radial-gradient(1000px ${height + paddingTop}px ellipse at 50% ${height + paddingTop}px, ${
            onboardingStyles.colors.lightPurple
        }, transparent)`};
        min-height: ${height + paddingTop}px;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: auto;
        text-align: center;
    `
)

export const CenteredImage = styled.img`
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    ${width};
`
