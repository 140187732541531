import { useCallback, useContext, useEffect, useState } from 'react'

import { useT } from '@transifex/react'
import { ThemeContext } from 'styled-components'
import { MainButton } from 'components/ui'
import { BillingSelect } from 'components/ui/Header/Billing/BillingSelect'
import { LanguagePicker } from 'components/ui/Header/LanguagePicker/LanguagePicker'
import { useBillingContext } from 'context/Billing/BillingContext'
import { useLanguageContext } from 'context/Language/LanguageContext'
import { useMedia } from 'hooks/useMedia'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { Language } from 'context/Language/language.types'

import { H4, H5, Icon, Loading, P, Wrapper } from '@rg/patternlab'

import { BillingModalTriggerBtn, SelectWrapper, StyledModal } from './BillingModal.styles'

export const BillingModal = ({ displayTriggerBtn = true }: { displayTriggerBtn?: boolean }) => {
    const t = useT()
    const theme = useContext(ThemeContext)
    const isDesktop = useMedia(`(min-width: ${theme.breakpoints.lg})`)
    const maxHeightMD = useMedia(`(max-height: ${theme.breakpoints.md})`)
    const maxWidthSM = useMedia(`(max-width: ${theme.breakpoints.sm})`)
    const isFullScreen = maxWidthSM || maxHeightMD

    const { billing, setBilling, hasBillingModalBeenOpened, setHasBillingModalBeenOpened } = useBillingContext()
    const [selectedBilling, setSelectedBilling] = useState(billing)

    const { language, handleSaveLanguage } = useLanguageContext()
    const [selectedLanguage, setSelectedLanguage] = useState(language)

    const onLanguageChange = (language: Language) => {
        setSelectedLanguage(language)
    }

    useEffect(() => {
        if (!billing.id) return

        setSelectedBilling(billing)
    }, [billing])

    useEffect(() => {
        setSelectedLanguage(language)
    }, [language])

    const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.stopPropagation()
        }
    }, [])

    const trigger = ({ setIsOpen }: { setIsOpen: (isOpen: boolean) => void }) => (
        <BillingModalTriggerBtn
            data-testid="billing-modal-trigger-button"
            onClick={() => {
                setIsOpen(true)
            }}
        >
            <Icon mr={theme.space.sm} size={21} icon="globe" color={onboardingStyles.colors.purple} />
            {isDesktop ? billing.country : <Icon size={21} icon="language" color={onboardingStyles.colors.purple} />}
        </BillingModalTriggerBtn>
    )

    if (!Object.keys(billing).length) {
        return <Loading position="relative" animationType="colorful" />
    }

    return (
        <StyledModal
            ariaLabel={t('Select Country and Language')}
            fullScreen={isFullScreen ? 'full' : 'floating'}
            isLocked={true}
            hasCloseButton={false}
            open={!hasBillingModalBeenOpened}
            {...(displayTriggerBtn && { trigger })}
            header={
                <H4 color={onboardingStyles.colors.darkBlue} my={theme.space.xs}>
                    {t('Select Country and Language')}
                </H4>
            }
            footer={({ setIsOpen }) => (
                <Wrapper display="flex" flexDirection="column" alignItems="center">
                    <MainButton
                        data-testid="billing-modal-save-button"
                        mt={theme.space.sm}
                        onKeyDown={handleKeyDown}
                        onClick={() => {
                            setBilling(selectedBilling)
                            handleSaveLanguage(selectedLanguage)
                            setHasBillingModalBeenOpened(true)
                            localStorage.setItem('billingId', selectedBilling.id.toString())
                            setIsOpen(false)
                        }}
                    >
                        {t('Save preferences')}
                    </MainButton>
                </Wrapper>
            )}
        >
            <Wrapper onKeyDown={handleKeyDown} display="flex" flexDirection="column">
                <Wrapper mb={theme.space.ml}>
                    <H5 color={onboardingStyles.colors.darkBlue}>{t('Where are your employees based?')}</H5>
                    <P color={onboardingStyles.colors.darkBlue}>
                        {t(
                            `This will be your Programme's country & the Billing country for your invoices. Once the Programme is created, the country cannot be changed.`
                        )}
                    </P>
                    <SelectWrapper zIndex={9999}>
                        <BillingSelect selectedBilling={selectedBilling} setSelectedBilling={setSelectedBilling} />
                    </SelectWrapper>
                </Wrapper>
                <Wrapper display="flex" flexDirection="column">
                    <H5 color={onboardingStyles.colors.darkBlue}>{t(`What's your preferred language?`)}</H5>
                    <P color={onboardingStyles.colors.darkBlue}>
                        {t(
                            `This will be your language during your Programme's creation. Your employees will have the flexibility to select their preferred language too.`
                        )}
                    </P>
                    <SelectWrapper zIndex={9998}>
                        <LanguagePicker
                            dataTestId="language-picker-modal"
                            minWidth="inherit"
                            maxWidth="unset"
                            selectedLanguage={selectedLanguage}
                            onLanguageChange={onLanguageChange}
                        />
                    </SelectWrapper>
                </Wrapper>
            </Wrapper>
        </StyledModal>
    )
}
