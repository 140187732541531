// https://www.apollographql.com/docs/link/links/schema/
import { SchemaLink } from '@apollo/client/link/schema'
import { makeExecutableSchema } from '@graphql-tools/schema'

import { typeDefs } from '../typeDefs'

/**
 * Construct your GraphQL schema here
 * You must have a root `Query` and `Mutation` type definition. These can be empty. Compose your different typedefs in the `typeDef` array below
 */
const generatedSchema = makeExecutableSchema({
    typeDefs,
})

// this allows us to perform GraphQL operations on provided schema
export const schema = new SchemaLink({ schema: generatedSchema })
