import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

const size = '20px'

export const StyledInput = styled.input`
    ${({ theme }) => css`
        appearance: none;
        border-radius: 50%;
        margin: ${theme.space.none};
        background-color: ${theme.palette.white};
        display: inline-block;
        vertical-align: middle;
        border: 1px solid ${theme.palette.greyscale.main['30']};
        transition:
            border-color 0.15s linear,
            box-shadow 0.15s linear;
        height: ${size};
        width: ${size};
        line-height: ${size};
        font-size: ${theme.fontSizes.md};

        &:focus {
            border-color: ${theme.palette.greyscale.main['90']};
            box-shadow: ${onboardingStyles.shadows.mainBox};
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            opacity: 0;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform-origin: top left;
            transform: scale(0) translate(-50%, -50%);
            transition:
                opacity 0.25s cubic-bezier(0.075, 0.82, 0.165, 1),
                transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
            height: 10px;
            width: 10px;
        }

        &:checked {
            &::after {
                box-sizing: border-box;
                background: ${theme.palette.primary.main['100']};
                opacity: 1;
                transform: scale(1) translate(-50%, -50%);
            }
        }
    `}
`

export const StyledLabel = styled.label`
    ${({ theme }) => css`
        font-weight: 500;
        margin-left: ${theme.space.sm};
        padding-left: ${theme.space.sm};
        padding-right: ${theme.space.sm};
    `}
`
