import { SpaceProps } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Icon } from '@rg/patternlab'
import { useTheme } from '@rg/common'

import { InfoMessageWrapper } from './InfoMessage.styles'

export const InfoMessage: React.FC<SpaceProps> = ({ children, ...props }) => {
    const theme = useTheme()

    return (
        <InfoMessageWrapper {...props}>
            <Icon
                icon="info"
                color={theme.palette.info.main['100']}
                size={onboardingStyles.dimensions.iconSize}
                mr={theme.space.sm}
            />
            <span>{children}</span>
        </InfoMessageWrapper>
    )
}
