import { QueryResult, useQuery } from '@apollo/client'
import { rgApiContext } from 'index.constants'

import { GET_BILLING_ENTITY } from '@rg/apollo/services/onboarding/billingEntity/billingEntity.queries'

export type Billing = {
    __typename?: 'BillingEntity'
    id: number
    currency: string
    currencySign: string
    chargebeeLocale: string
    phonePrefix: string
    country: string
    requestDemoLink: string
}

/**
 * Custom hook to fetch billing entity data.
 *
 * @param {number} [billingEntityId] - The ID of the billing entity. If provided, the function will fetch the data for this specific billing entity. If not provided, the function will fetch the data for all billing entities.
 * @returns The response object from the Apollo useQuery hook, which includes loading, error, and data properties.
 */

export const useGetBillingEntity = (
    billingEntityId?: number | undefined
): QueryResult<{ billingEntity: Array<Billing> }> =>
    useQuery(GET_BILLING_ENTITY, {
        variables: {
            billingEntityId: billingEntityId ? `?locale=${billingEntityId}` : '',
        },
        context: rgApiContext,
    })
