import { useMemo } from 'react'
import { Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { scrollToTop } from 'utils/scrollToTop'

import { useTheme } from '@rg/common'
import { Icon, Wrapper } from '@rg/patternlab'
import { IconType } from '@rg/patternlab/components/Icon/Icon.types'

/*
  BannerNotifications should be displayed at the top of the screen, below the nav bar.
  They're persistent and non-modal, allowing the user to either ignore them or interact with them at any time.
  ? Allow more than one BannerNotifications at a time?
*/

import {
    BannerNotificationMessage,
    NotificationCloseButton,
    NotificationInnerWrapper,
    StyledBannerNotificationContainer,
} from './BannerNotification.styles'
import { BannerNotificationProps, BarNotifyProps } from './BannerNotification.types'

const BannerNotification: React.FC<BannerNotificationProps> = ({ children, variant, closeToast }) => {
    const theme = useTheme()

    const iconProps: { icon: IconType; color: string } = useMemo(() => {
        switch (variant) {
            case 'success':
                return {
                    color: theme.palette.primary.main['100'],

                    icon: 'checked',
                }
            case 'error':
                return {
                    color: onboardingStyles.colors.red,
                    icon: 'puzzled',
                }
            case 'warning':
                return {
                    color: onboardingStyles.colors.yellow,
                    icon: 'checked',
                }
            default:
                return {
                    color: onboardingStyles.colors.lightBlue,
                    icon: 'checked',
                }
        }
    }, [variant])

    return (
        <Wrapper
            display="flex"
            alignItems={{ default: 'start', md: 'center' }}
            justifyContent="space-between"
            data-testid={`banner-${variant}-notification`}
            backgroundColor={iconProps.color}
        >
            <NotificationInnerWrapper p={theme.space.sm}>
                <Icon
                    icon={iconProps.icon}
                    display="flex"
                    color={theme.palette.white}
                    marginRight={theme.space.sm}
                    size={32}
                />
                {children}
            </NotificationInnerWrapper>
            <NotificationCloseButton aria-label="Close notification" onClick={closeToast}>
                <Icon
                    icon="delete"
                    size={onboardingStyles.dimensions.iconSize}
                    color={theme.palette.white}
                    verticalAlign="baseline"
                    stroke
                />
            </NotificationCloseButton>
        </Wrapper>
    )
}

export const BannerNotificationContainer: React.FC = () => (
    // ? allow props from ToastContainer props? (make sure to reject specific ones)
    <StyledBannerNotificationContainer
        autoClose={false}
        closeButton={false}
        draggable={false}
        hideProgressBar
        transition={Slide}
        enableMultiContainer
        containerId="banner"
    />
)

export const bannerNotify = ({ id = undefined, message, variant = 'info', onClose }: BarNotifyProps): void => {
    toast(
        <BannerNotification variant={variant}>
            {typeof message === 'string' ? <BannerNotificationMessage>{message}</BannerNotificationMessage> : message}
        </BannerNotification>,
        {
            onClose,
            position: toast.POSITION.TOP_CENTER,
            toastId: id,
            containerId: 'banner',
        }
    )

    scrollToTop()
}

// https://fkhadra.github.io/react-toastify/remove-toast/
export const dismissBannerNotifications = toast.dismiss
