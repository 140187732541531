// https://www.apollographql.com/docs/link/links/error/
import { onError } from '@apollo/client/link/error'
import { bannerNotify } from 'components/ui'
import { STATUS_CODES } from 'index.constants'
import { SomethingWentWrongNotificationMessage } from 'components/ui/BannerNotification/SomethingWentWrongNotificationMessage'

export const validationErrors = {
    existingSchemeAlias: 1010,
    invalidPhoneNumber: 1011,
    invalidPostcode: 1012,
    invalidCompanyRegistrationNumber: 1013,
}

export const emailRelatedErrors = {
    duplicateOnboardingRecord: 1001,
    emailBlocked: 1002,
    existingRGClient: 1008,
    existingRGSBClient: 1009,
}

export const subscriptionErrors = {
    subscriptionExists: 1023,
}

export const validationErrorCodes = Object.values(validationErrors)
export const emailRelatedErrorCodes = Object.values(emailRelatedErrors)

export const validationErrorsMessageFromBE = 'Validation errors'

export type ValidationErrors = {
    firstName?: string
    lastName?: string
    companyRegistrationNumber?: string
    schemeAlias?: string
    phoneNumber?: string
    postalCode?: string
}

export type ErrorObject = { result: { code: number; details: ValidationErrors; message: string } }

export const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            // eslint-disable-next-line no-console
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )

    const hasStatusCode = networkError && 'statusCode' in networkError

    let isServerError
    if (hasStatusCode) {
        isServerError = networkError.statusCode === STATUS_CODES.SERVER_ERROR
    }

    if (networkError && hasStatusCode && isServerError) {
        bannerNotify({
            id: 'network-error-notification',
            variant: 'error',
            message: <SomethingWentWrongNotificationMessage />,
        })
    }

    if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`)
    }
})
