import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

const primaryColor = onboardingStyles.colors.lightPurple
const focusColor = onboardingStyles.colors.lightBlue

export const RatingItemWrapper = styled.div(
    ({ theme }) => css`
        label {
            display: flex;
            width: 40px;
            height: 40px;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: ${theme.space.md};
        }

        input[type='radio'] {
            /* hide input while having it readable by screen readers */
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: ${theme.space.none};
            position: absolute !important;
            white-space: nowrap;
            width: 1px;

            svg {
                transition: all 0.15s ease-out;
                transform: scale(1);

                .head-fill {
                    fill: ${theme.palette.white};
                }

                .head-stroke {
                    fill: ${primaryColor};
                }

                .face-elements {
                    fill: ${primaryColor};
                }
            }
        }

        input[type='radio']:hover + label,
        input[type='radio']:checked:hover + label {
            svg {
                transition: all 0.15s ease-out;
                transform: scale(1.1);

                .head-fill {
                    fill: ${primaryColor};
                }

                .face-elements {
                    fill: ${theme.palette.white};
                }
            }
        }

        input[type='radio']:focus + label,
        input[type='radio']:checked:focus + label {
            svg {
                transition: all 0.15s ease-out;
                transform: scale(1.1);

                .head-fill {
                    fill: ${primaryColor};
                }

                .head-stroke {
                    fill: ${focusColor};
                }

                .face-elements {
                    fill: ${theme.palette.white};
                }
            }
        }

        input[type='radio']:checked + label {
            svg {
                transition: all 0.15s ease-out;
                transform: scale(1);

                .head-fill {
                    fill: ${primaryColor};
                }

                .face-elements {
                    fill: ${theme.palette.white};
                }
            }
        }
    `
)
