import { lighten } from 'polished'

import { ColorsProps, DimensionsProps, ElementColorsProps, FontProps, ShadowsProps } from './onboardingStyles.types'

export const colors = {
    lighterBlue: '#C9D8F0',
    lightBlue: '#007BC8',
    darkBlue: '#003865',
    darkGreen: '#0c6e00',
    red: '#a90000',
    lighterRed: '#FFE2E2',
    purple: '#452159',
    lightPurple: '#794370',
    yellow: '#f7b500',
}

export const elementColors = {
    bodyTxt: colors.darkBlue,
    link: colors.lightPurple,
    linkHover: lighten(0.1, colors.lightPurple),
    heading: colors.darkBlue,
    primaryBtnBg: colors.yellow,
    primaryBtnHoverBg: lighten(0.04, colors.yellow),
    primaryBtnTxt: colors.purple,
    secondaryBtnBg: colors.purple,
    secondaryBtnHoverBg: lighten(0.08, colors.purple),
    secondaryBtnTxt: colors.yellow,
}

export const dimensions: DimensionsProps = {
    bodyMinWidth: '375px',
    buttonDefaultWidth: '320px',
    narrowContainerMaxWidth: '546px',
    shortInputMaxWidth: '288px',
    topBarHeight: {
        default: '80px',
        sm: '100px',
    },
    iconSize: 18,
}

export const shadows = {
    mainBox: `0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.07)`,
    focusBox: '0 3px 10px 0 rgba(0, 56, 101, 0.15)',
}

export const font = {
    family: 'GothamRounded',
}

export const onboardingStyles = {
    dimensions,
    font: font as FontProps,
    colors: colors as ColorsProps,
    elementColors: elementColors as ElementColorsProps,
    shadows: shadows as ShadowsProps,
}
