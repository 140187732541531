import styled, { css } from 'styled-components'

export const StyledNumberInput = styled.input(
    ({ theme }) => css`
        width: 100%;
        padding: ${theme.space.sm};
        border: 0;
        border-radius: 5px;
        font-size: ${theme.fontSizes.lg};
        text-align: center;
        background-color: ${theme.palette.greyscale.main['10']};
    `
)
