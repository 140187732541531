import styled, { css } from 'styled-components'

import { SawtoothWrapperProps } from './SawtoothWrapper.types'

export const SawtoothWrapper = styled.div<SawtoothWrapperProps>(
    ({ bgColour, bottomColour, topColour }) => css`
        background-color: ${bgColour};
        margin: 45px 0;
        position: relative;
        box-shadow: rgb(0 0 0 / 10%) 0 0 25px 0;

        &::before,
        &::after {
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
        }

        &::before {
            background:
                linear-gradient(-135deg, transparent 75%, ${topColour} 75%) 0 50% / 45px 45px repeat-x,
                linear-gradient(135deg, transparent 75%, ${topColour} 75%) 0 50% / 45px 45px;
            background-repeat: repeat-x;

            /* not sure yet how these values relate to each other */
            background-size:
                45px 45px,
                45px 45px;
            top: -52px;
            height: 59px;
        }

        &::after {
            background:
                linear-gradient(-45deg, transparent 75%, ${bottomColour} 75%) 0 50% / 45px 45px repeat-x,
                linear-gradient(45deg, transparent 75%, ${bottomColour} 75%) 0 50% / 45px 45px;
            background-repeat: repeat-x;

            /* not sure yet how these values relate to each other */
            background-size:
                45px 45px,
                45px 45px;
            bottom: -52px;
            height: 59px;
        }
    `
)

SawtoothWrapper.defaultProps = {
    bgColour: 'white',
    bottomColour: 'white',
    topColour: 'white',
}
