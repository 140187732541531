import { FC } from 'react'

import { StepLabel, StyledWrapper } from './StepsLabels.styles'
import { StepsLabelsProps } from './RangeSlider.types'

export const StepsLabels: FC<StepsLabelsProps> = ({ min = '1', mid = '50', max = '100' }) => (
    <StyledWrapper data-testid="range-slider-steps-labels">
        <StepLabel data-testid="range-slider-min-step-label">{min}</StepLabel>
        <StepLabel data-testid="range-slider-mid-step-label" pr="5px" textAlign="center">
            {mid}
        </StepLabel>
        <StepLabel data-testid="range-slider-max-step-label" textAlign="right">
            {max}
        </StepLabel>
    </StyledWrapper>
)
