import { useContext } from 'react'
import { useLocation } from 'react-router'

import { ThemeContext } from 'styled-components'
import { Container } from 'components/ui'
import logo from 'images/rgsb_logo.png'
import { BillingModal } from 'components/ui/Header/Billing/BillingModal'
import { useMedia } from 'hooks/useMedia'
import { getUrlParams } from 'utils/helpers'

import { LanguagePicker } from './LanguagePicker/LanguagePicker'
import { HeaderInner, HeaderWrapper, InnerWrapper, LogoWrapper } from './Header.styles'

export const Header = () => {
    const theme = useContext(ThemeContext)
    const isDesktop = useMedia(`(min-width: ${theme.breakpoints.lg})`)
    const locationPathname = useLocation().pathname
    const isNotPricingPage: boolean = locationPathname !== '/pricing'
    const displayLanguagePicker = isDesktop || isNotPricingPage
    const isEditing = getUrlParams('isEditing') === 'true'
    const displayBillingModal = locationPathname === '/pricing' && !isEditing

    return (
        <HeaderWrapper>
            <Container>
                <HeaderInner>
                    <LogoWrapper
                        src={logo}
                        alt="Reward Gateway for Small Business"
                        maxWidth={{
                            default: '130px',
                            sm: '160px',
                        }}
                    />
                    <InnerWrapper>
                        {displayBillingModal && <BillingModal />}
                        {displayLanguagePicker && <LanguagePicker dataTestId="language-picker-header" />}
                    </InnerWrapper>
                </HeaderInner>
            </Container>
        </HeaderWrapper>
    )
}
