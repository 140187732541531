import styled, { css } from 'styled-components'
import { SpaceProps, space } from 'styled-system'
import { StyledTextButton } from 'components/ui'

export const InfoMessageWrapper = styled.div<SpaceProps>(
    ({ theme }) => css`
        display: flex;
        align-items: start;
        font-size: ${theme.fontSizes.sm};
        font-weight: 400;
        color: ${theme.palette.info.main['100']};
        margin-top: ${theme.space.xs};
        ${space};

        a,
        ${StyledTextButton} {
            color: ${theme.palette.info.main['100']};

            &,
            &:hover,
            &:focus,
            &:active {
                color: ${theme.palette.info.main['100']};
            }
        }

        ${theme.mediaQueries.sm} {
            ${theme.fontSizes.sm}
        }
    `
)
