import { Dispatch, SetStateAction, useContext } from 'react'
import { SingleValueProps, components } from 'react-select'

import { ThemeContext } from 'styled-components'
import { useT } from '@transifex/react'
import { Select } from 'components/ui'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { Billing } from 'hooks/useGetBillingEntity'
import { useBillingContext } from 'context/Billing/BillingContext'

import { Icon, Strong } from '@rg/patternlab'

import { BillingSelectWrapper } from './BillingModal.styles'

const SingleValueWIthIcon = (props: SingleValueProps<Billing & { icon: JSX.Element }>) => {
    return (
        <components.SingleValue {...props}>
            {props.data.icon}
            <Strong>{props.data.country}</Strong>
        </components.SingleValue>
    )
}

export const BillingSelect = ({
    selectedBilling,
    setSelectedBilling,
}: {
    selectedBilling: Billing
    setSelectedBilling: Dispatch<SetStateAction<Billing>>
}) => {
    const t = useT()
    const theme = useContext(ThemeContext)
    const { billingOptions } = useBillingContext()

    return (
        <BillingSelectWrapper data-automation="billing-country-select" data-testid="billing-country-select">
            <Select
                ariaLabel={selectedBilling.country}
                components={{ SingleValue: SingleValueWIthIcon }}
                name="billing"
                type="select"
                onChange={(_name: string, value: Billing) => {
                    setSelectedBilling(value)
                }}
                value={{
                    ...selectedBilling,
                    icon: <Icon mr={theme.space.sm} size={21} icon="globe" color={onboardingStyles.colors.purple} />,
                    label: <Strong>{selectedBilling.country}</Strong>,
                }}
                getOptionLabel={(option: Billing) => option.country}
                getOptionValue={(option: Billing) => option.id}
                placeholder={t('Select Billing Country')}
                options={billingOptions}
            />
        </BillingSelectWrapper>
    )
}
