import { ApiURL } from 'index.types'

import { CouponType } from '@rg/apollo/services/referrals/referrals.types'
import { PlanType } from '@rg/apollo/services/scheme/subscription/subscription.types'

import { TranslatedStep } from './components/ui'

const API_URL = process.env.REACT_APP_RG_API_URL
export const isEnvironmentProduction: boolean = API_URL === ApiURL.Production

export const getSteps = (t: Function): TranslatedStep[] => {
    return [
        {
            route: '/sign-up',
            link: '/sign-up',
            labelTextString: t('Create an account'),
            automationid: 'create-account-step',
        },
        {
            route: '/confirm-email',
            labelTextString: t('Confirm your email'),
            automationid: 'confirm-email-step',
        },
        {
            route: ['/decision', '/billing-details', '/kyc'],
            link: '/billing-details',
            labelTextString: t('Set up billing'),
            automationid: 'setup-account-step',
        },
        {
            route: '/summary',
            link: '/summary',
            labelTextString: t('Summary'),
            automationid: 'summary-step',
        },
    ]
}

// This is the default value of the feature flags and they need to be lowercase
export const FEATURE_FLAGS: Record<string, boolean> = {
    autofillform: false,
}

export const rgApiContext = {
    headers: {
        Accept: 'application/vnd.rewardgateway+json;version=3.0',
    },
}

export const applicableDiscountCouponTypes = [
    CouponType.TwentyFiveForever,
    CouponType.FourtyPercentForever,
    CouponType.FiftyPercentForever,
    CouponType.TwentyPercentAYear,
    CouponType.TenPercentFirstYear,
    CouponType.TenPercentForever,
    CouponType.ForeverFree,
]

export const DEFAULT_SEAT_COUNT = 50
export const MAX_SEAT_COUNT = 150
export const PRICING_PAGE_DEF_PLAN = PlanType.RGSB_ANNUAL_MONTHLY
export const SUPPORTED_PLANS = Object.values(PlanType)
export const IKEA_COMPANY_NUMBER_RULE = '/^627598619\\d{10}$/'

export const STATUS_CODES = {
    SERVER_ERROR: 500,
}

export const globalAny: any = global
