import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { StampText } from './components/StampText.styles'

export const ForeverFreeStampText = styled(StampText)(
    ({ theme }) => css`
        line-height: 1;
        padding-top: 10px;

        & span:nth-child(1) {
            color: ${onboardingStyles.colors.darkBlue};
            font-weight: 900;
            font-size: ${theme.fontSizes.xxxl};
        }

        & span:nth-child(2) {
            font-size: ${theme.fontSizes.xl};
        }

        ${theme.mediaQueries.sm} {
            & span:nth-child(1) {
                font-size: ${theme.fontSizes.xxxl};
            }

            & span:nth-child(2) {
                font-size: ${theme.fontSizes.xl};
            }
        }
    `
)
