// https://www.apollographql.com/docs/link/links/context/
import { setContext } from '@apollo/client/link/context'

export const authLink = setContext(({ operationName }, { headers }) => {
    const noAuthOperations = ['getPlansByLocaleId', 'getAddons']
    const accessToken = localStorage.getItem('accessToken')
    const isOperationWithAuth = operationName ? !noAuthOperations.includes(operationName) : true
    const enableAuthorizationHeader = isOperationWithAuth && accessToken

    return {
        headers: {
            ...(enableAuthorizationHeader && { authorization: accessToken }),
            accept: 'application/vnd.rewardgateway+json;version=3',
            ...headers,
        },
    }
})
