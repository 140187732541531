import { SyntheticEvent, useRef } from 'react'

import {
    CheckboxContainer,
    HiddenCheckbox,
    Icon,
    Label,
    LabelText,
    StyledCheckbox,
    Wrapper,
} from 'components/ui/Checkbox/Checkbox.styles'

import { CheckboxProps } from './Checkbox.types'

export const Checkbox = (props: CheckboxProps) => {
    const { name, className, checked, label, onChange, onBlur } = props
    const labelRef = useRef<HTMLLabelElement>(null)

    const handleChange = (e: SyntheticEvent) => onChange(name, (e.target as HTMLInputElement).checked)
    const handleBlur = () => onBlur(name, true)
    // click anywhere in the wrapper and it will toggle the checkbox (no need to click exaclty over the label text)
    const handleClick = () => labelRef.current?.click()

    return (
        <Wrapper data-testid="terms-form-accept-checkbox" onClick={handleClick}>
            <Label ref={labelRef}>
                <CheckboxContainer className={className}>
                    <HiddenCheckbox checked={checked} onChange={handleChange} onBlur={handleBlur} />
                    <StyledCheckbox checked={checked}>
                        <Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                        </Icon>
                    </StyledCheckbox>
                </CheckboxContainer>
                <LabelText>{label}</LabelText>
            </Label>
        </Wrapper>
    )
}
