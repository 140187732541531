import { useContext } from 'react'
import { SingleValueProps, components } from 'react-select'

import { ThemeContext } from 'styled-components'
import { useT } from '@transifex/react'
import { useMedia } from 'hooks/useMedia'
import { Select } from 'components/ui'
import { useLanguageContext } from 'context/Language/LanguageContext'
import { Language } from 'context/Language/language.types'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Icon, Wrapper } from '@rg/patternlab'

import { LanguagePickerProps } from './LanguagePicker.types'
import { makeLanguageBold } from './helpers'

const SingleValueWIthIcon = (props: SingleValueProps<Language & { icon: JSX.Element }>) => (
    <components.SingleValue {...props}>
        {props.data.icon}
        {makeLanguageBold(props.data.localized_name)}
    </components.SingleValue>
)

export const LanguagePicker = ({
    minWidth = '350px',
    maxWidth = 'fit-content',
    selectedLanguage,
    onLanguageChange,
    dataTestId,
}: LanguagePickerProps) => {
    const t = useT()
    const theme = useContext(ThemeContext)
    const isMd = useMedia(`(min-width: ${theme.breakpoints.md})`)

    const { language, handleSaveLanguage, txLanguages } = useLanguageContext()
    const value = selectedLanguage ?? language

    if (!language.localized_name || !value.localized_name) return null
    return (
        <Wrapper
            width="100%"
            minWidth={isMd ? minWidth : 'initial'}
            maxWidth={isMd ? maxWidth : 'none'}
            data-automation="country-dropdown-details"
            data-testid={dataTestId}
        >
            <Select
                ariaLabel={value.localized_name}
                components={{
                    SingleValue: SingleValueWIthIcon,
                }}
                type="select"
                name="language"
                onChange={(_name: string, value: Language) => {
                    if (onLanguageChange) {
                        //in case of change from modal
                        onLanguageChange(value)
                    } else {
                        //in case of change from header
                        handleSaveLanguage(value)
                    }
                }}
                options={txLanguages}
                getOptionLabel={(option: Language) => option.localized_name}
                getOptionValue={(option: Language) => option.code}
                formatOptionLabel={(option: Language) => makeLanguageBold(option.localized_name)}
                placeholder={t('Select Language')}
                value={{
                    ...value,
                    icon: <Icon mr={theme.space.sm} size={21} icon="language" color={onboardingStyles.colors.purple} />,
                }}
            />
        </Wrapper>
    )
}
