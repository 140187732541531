import { Lottie } from '@crello/react-lottie'
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system'

import { Wrapper } from '@rg/patternlab'

import { AnimationProps } from './Animation.types'

export const Animation: React.FC<AnimationProps & SpaceProps & LayoutProps & FlexboxProps> = ({
    data,
    width,
    height,
    ariaRole,
    ariaLabel,
    ...props
}) => {
    const config = {
        loop: true,
        autoplay: true,
        animationData: data,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <Wrapper
            {...(ariaRole && { role: ariaRole })}
            {...(ariaLabel && { 'aria-label': ariaLabel })}
            data-testid="loading"
            display="flex"
            flexGrow={1}
            {...props}
        >
            <Lottie config={config} style={{ width: `${width}px`, height: `${height}px` }} />
        </Wrapper>
    )
}
