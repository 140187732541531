import Select from 'react-select'

import styled, { DefaultTheme, css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Span, Wrapper } from '@rg/patternlab'

export const SelectWrapper = styled(Wrapper)(
    ({ theme }) => css`
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid ${theme.palette.greyscale.main['30']};
    `
)

const getSelectBorder = ({
    hasError,
    isDisabled,
    theme,
}: {
    hasError?: boolean
    isDisabled?: boolean
    theme: DefaultTheme
}) => {
    let borderStyles = null

    if (isDisabled) {
        borderStyles = `1px solid ${theme.palette.greyscale.main['30']} !important;`
    } else if (hasError) {
        borderStyles = `1px solid ${onboardingStyles.colors.red} !important;`
    }
    return borderStyles
}

export const StyledSelect = styled(Select)(
    ({ theme }) => css`
        background-color: ${theme.palette.greyscale.main['10']};
        width: 100%;

        span {
            min-width: fit-content;
        }

        .rg {
            &__control {
                background-color: transparent;
                border: 1px solid ${theme.palette.greyscale.main['10']};
                border-radius: 0;
                cursor: pointer;
                padding: ${theme.space.none} ${theme.space.sm};

                ${theme.mediaQueries.md} {
                    padding: ${theme.space.none} ${theme.space.md};
                }

                &:hover,
                &:focus {
                    border: 1px solid ${onboardingStyles.colors.lighterBlue};
                }

                ${({ isDisabled, hasError }: { isDisabled: boolean; hasError?: boolean }) => css`
                    border: ${getSelectBorder({ hasError, isDisabled, theme })};
                    background-color: ${hasError ? onboardingStyles.colors.lighterRed : null};
                `}

                &--is-focused,
                &--is-focused:hover,
                &--is-focused:focus {
                    border: 1px solid ${onboardingStyles.colors.lightBlue};
                    box-shadow: ${onboardingStyles.shadows.mainBox};
                }

                &--menu-is-open {
                    /* stylelint-disable -- stylelint prefers kebab-case, these are going to be deleted once we adopt Select from patternlab*/
                    .rg__indicator {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                    /* stylelint-enable */
                }
            }

            &__input {
                input {
                    /* temp-fix */
                    width: 100% !important;
                    font-family: ${onboardingStyles.font.family};
                }
            }

            &__value-container {
                padding: ${theme.space.sm} ${theme.space.none};

                ${theme.mediaQueries.sm} {
                    padding: ${theme.space.md} ${theme.space.none};
                }

                > * {
                    padding: ${theme.space.none};
                    margin: ${theme.space.none};
                }
            }

            &__placeholder {
                margin: ${theme.space.none};
                padding: ${theme.space.none};
            }

            &__indicator-separator {
                display: none;
            }

            &__indicator,
            &__dropdown-indicator {
                padding: ${theme.space.none} ${theme.space.none} ${theme.space.none} ${theme.space.xs};
            }

            &__indicator {
                svg {
                    color: ${({ isDisabled }) =>
                        isDisabled ? theme.palette.greyscale.main['30'] : theme.palette.greyscale.main['50']};
                    transition: transform 0.2s ease-out;
                }
            }

            &__menu-list {
                display: flex;
                flex-direction: column;
                padding: ${theme.space.none};
                overflow: auto;
            }

            &__menu-list::-webkit-scrollbar {
                width: 8px;
            }

            &__menu-list::-webkit-scrollbar-track {
                background: ${theme.palette.greyscale.main['30']};
            }

            &__menu-list::-webkit-scrollbar-thumb {
                background: ${theme.palette.greyscale.main['50']};

                &:hover,
                &:focus {
                    background: ${theme.palette.greyscale.main['70']};
                }
            }

            &__menu {
                top: 100%;
                position: absolute;
                z-index: 1300;
                margin-top: ${theme.space.none};
                border-radius: 0;
                text-align: left;
            }

            &__single-value {
                font-family: ${onboardingStyles.font.family};
                position: absolute;
                display: flex;
                align-items: center;
                color: ${onboardingStyles.colors.darkBlue};
                ${({ isDisabled }) =>
                    isDisabled &&
                    css`
                        color: ${theme.palette.disabled.main['100']};
                        opacity: 0.5;
                        filter: grayscale(100%);
                    `}
                font-size: ${theme.fontSizes.sm};
                font-weight: 600;

                ${theme.mediaQueries.md} {
                    font-size: ${theme.fontSizes.lg};
                    font-weight: 400;
                }
            }

            &__option {
                display: flex;
                color: ${onboardingStyles.colors.darkBlue};
                cursor: pointer;
                padding: ${theme.space.sm};
                max-height: 55px;
                border-bottom: 1px solid ${theme.palette.greyscale.main['30']};

                ${theme.mediaQueries.md} {
                    padding: ${theme.space.md};
                    font-size: ${theme.fontSizes.lg};
                }

                &:active {
                    background-color: ${onboardingStyles.colors.purple};
                    color: ${theme.palette.white};
                }

                &--is-selected {
                    background-color: ${theme.palette.white};
                    color: ${onboardingStyles.colors.darkBlue};
                }

                &--is-focused {
                    background-color: ${onboardingStyles.colors.purple};
                    color: ${theme.palette.white};
                }
            }
        }
    `
)

export const InputLabel = styled.div(
    ({ theme }) => css`
        padding-left: 22px;
        padding-right: 22px;
        color: ${theme.palette.greyscale.main['50']};
        font-family: ${onboardingStyles.font.family};
        opacity: 1; /* Firefox */
        position: absolute;
        z-index: 1100;
        pointer-events: none;
        font-size: ${theme.fontSizes.md};

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.lg};
        }
    `
)

export const StyledSelectFloatingLabel = styled(StyledSelect)(
    ({ theme }) => css`
        .rg {
            &__value-container {
                padding: 26px ${theme.space.none} 6px;
            }

            &__single-value {
                top: 39px;
            }
        }
    `
)

export const IconWrapper = styled(Span)(
    ({ theme }) => css`
        margin-right: ${theme.space.sm};
        display: flex;

        ${theme.mediaQueries.md} {
            margin-right: ${theme.space.sm};
        }

        svg {
            width: 30px;
            height: auto;

            ${theme.mediaQueries.md} {
                width: 32px;
            }
        }
    `
)
