import styled, { css } from 'styled-components'
import { compose, space } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { getStyledInputBackgroundColor, getStyledInputBorder } from 'utils/helpers'

import { FieldMessage, Wrapper } from '@rg/patternlab'
import { Field, StyledInputWrapper } from '@rg/patternlab/components/Form/Form.styles'

export const MainWrapper = styled(Wrapper)<{ disabled?: boolean; hasError?: boolean; short?: boolean }>(
    ({ theme, disabled, hasError, short }) => css`
        width: 100%;

        ${Field} {
            flex: 1;
            width: ${short ? '60%' : '100%'};
            margin: ${theme.space.md} ${theme.space.none} ${theme.space.none};
        }

        ${StyledInputWrapper} {
            display: block;
            flex: 1;
            width: 100%;
            border: 1px solid ${getStyledInputBorder(hasError, disabled)};
            border-radius: 0;
            background-color: ${getStyledInputBackgroundColor(theme.palette.white, hasError, disabled)};
            box-shadow: none;

            label {
                font-size: ${theme.fontSizes.md};
                font-weight: 400;
                color: ${theme.palette.greyscale.main['80']};
            }

            input {
                color: ${disabled ? theme.palette.disabled.main['100'] : onboardingStyles.colors.darkBlue};
            }
        }

        ${compose(space)};
    `
)

export const StyledFieldMessage = styled(FieldMessage)(
    ({ theme }) => css`
        margin-top: ${theme.space.sm};
        font-family: ${onboardingStyles.font.family};

        svg {
            min-width: 18px;
            width: 18px;
            height: 18px;
        }
    `
)

export const InnerWrapper = styled(Wrapper)<{ append?: boolean }>(
    ({ theme, append }) => css`
        display: ${append ? 'flex' : 'block'};
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        ${theme.mediaQueries.md} {
            flex-direction: row;
        }
    `
)

export const InputAppend = styled.div(
    ({ theme }) => css`
        display: block;
        width: 100%;
        padding: ${theme.space.xs} ${theme.space.md};
        font-size: ${theme.fontSizes.lg};
        font-weight: 600;
        color: ${onboardingStyles.colors.darkBlue};
        background-color: ${onboardingStyles.colors.lighterBlue};

        ${theme.mediaQueries.md} {
            width: auto;
            margin-left: ${theme.space.sm};
            padding: ${theme.space.md} 0 ${theme.space.sm};
            font-size: ${theme.fontSizes.xl};
            background-color: transparent;
        }
    `
)
