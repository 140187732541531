import Popup from 'reactjs-popup'

import styled, { css } from 'styled-components'

import { Modal } from '@rg/patternlab'

export const ModalContainer = styled.div(
    ({ theme }) => css`
        background: ${theme.palette.white};
        max-height: 80vh;
        padding: ${theme.space.md};

        ${theme.mediaQueries.md} {
            padding: ${theme.space.lg};
        }
    `
)

export const CloseButton = styled.button(
    ({ theme }) => css`
        appearance: none;
        margin: ${theme.space.none} ${theme.space.none} ${theme.space.none} ${theme.space.md};
        padding: ${theme.space.none};
        border: none;
        cursor: pointer;
        background-color: transparent;
    `
)

export const StyledPopup = styled(Popup)(
    ({ theme }) => css`
        &-overlay {
            z-index: 1200 !important;
        }

        &-content {
            overflow-y: auto;
            padding: ${theme.space.none};
            width: 100%;
            height: 100%;
            max-height: 100%;

            ${theme.mediaQueries.md} {
                min-width: 720px;
                width: 50%;
                height: auto;
            }
        }
    `
)

export const StyledModal = styled(Modal)(
    ({ theme }) => css`
        padding: ${theme.space.md};

        svg {
            font-size: ${theme.fontSizes.xxl};
            margin: ${theme.space.ml};
        }

        path {
            stroke: black;
            stroke-width: 0.5px;
        }
    `
)
