import { ReactNode } from 'react'

import { Wrapper } from '@rg/patternlab'

import { Ribbon, RibbonText } from './StampWithRibbon.styles'
import { Stamp } from './Stamp'

export const StampWithRibbon = ({ children, ribbonText }: { children: ReactNode; ribbonText?: string }) => {
    return (
        <Wrapper display="flex" flexDirection="column" alignItems="center" data-testid="stamp">
            <Stamp>{children}</Stamp>
            {ribbonText && (
                <Ribbon data-testid="stamp-ribbon">
                    <RibbonText>{ribbonText}</RibbonText>
                </Ribbon>
            )}
        </Wrapper>
    )
}
