// todo: replace commented paths with 16x16
export const iconPaths = {
    cancel: 'M 7.5 1 C 3.917969 1 1 3.917969 1 7.5 C 1 11.082031 3.917969 14 7.5 14 C 11.082031 14 14 11.082031 14 7.5 C 14 3.917969 11.082031 1 7.5 1 Z M 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 4.457031 13 2 10.542969 2 7.5 C 2 4.457031 4.457031 2 7.5 2 Z M 5.5 4.792969 L 4.792969 5.5 L 5.148438 5.851563 L 6.792969 7.5 L 5.148438 9.148438 L 4.792969 9.5 L 5.5 10.207031 L 5.851563 9.851563 L 7.5 8.207031 L 9.148438 9.851563 L 9.5 10.207031 L 10.207031 9.5 L 9.851563 9.148438 L 8.207031 7.5 L 9.851563 5.851563 L 10.207031 5.5 L 9.5 4.792969 L 9.148438 5.148438 L 7.5 6.792969 L 5.851563 5.148438 Z',
    check: 'M 7.5 1.941406 L 5.472656 2.953125 L 3 2.015625 L 3 10 L 2.523438 10 C 1.699219 10 1.023438 10.675781 1.023438 11.5 L 1 13.996094 L 2 14.003906 L 2.023438 11.5 C 2.023438 11.21875 2.238281 11 2.523438 11 L 3 11 L 3 13 L 12 13 L 12 11 L 12.5 11 C 12.78125 11 13 11.21875 13 11.5 L 13 14 L 14 14 L 14 11.5 C 14 10.675781 13.324219 10 12.5 10 L 12 10 L 12 2.015625 L 9.527344 2.953125 Z M 7.5 3.058594 L 9.472656 4.046875 L 9.679688 3.96875 L 11 3.464844 L 11 12 L 4 12 L 4 3.464844 L 5.527344 4.046875 Z M 5 6 L 5 7 L 7.992188 7.007813 L 7.992188 6.007813 Z M 9.003906 6 L 8.996094 7 L 9.996094 7.007813 L 10.003906 6.007813 Z M 5 9 L 5 10 L 8 10 L 8 9 Z M 9 9 L 9 10 L 10 10 L 10 9 Z',
    checked:
        'm7.679688.957031c-3.582032-.101562-6.574219 2.734375-6.675782 6.316407-.101562 3.582031 2.734375 6.578125 6.316407 6.679687 3.582031.097656 6.578125-2.734375 6.679687-6.320312.019531-.75-.09375-1.472657-.3125-2.148438l-.824219.777344c.097657.433594.148438.882812.136719 1.347656-.085937 3.03125-2.621094 5.425781-5.652344 5.34375-3.03125-.085937-5.429687-2.621094-5.347656-5.652344.085938-3.03125 2.621094-5.429687 5.652344-5.347656.992187.03125 1.914062.324219 2.707031.8125l.75-.710937c-.984375-.65625-2.15625-1.0625-3.429687-1.097657zm4.820312 1.835938-6 6-1.648437-1.644531-.351563-.355469-.707031.707031.355469.351563 2.351562 2.355468 6.707031-6.707031z',
    circleCheck:
        // viewBoxSize = 32x32
        'M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 22.28125 11.28125 L 15 18.5625 L 10.71875 14.28125 L 9.28125 15.71875 L 14.28125 20.71875 L 15 21.40625 L 15.71875 20.71875 L 23.71875 12.71875 Z',
    circleClose:
        // viewBoxSize = 32x32
        'M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 12.21875 10.78125 L 10.78125 12.21875 L 14.5625 16 L 10.78125 19.78125 L 12.21875 21.21875 L 16 17.4375 L 19.78125 21.21875 L 21.21875 19.78125 L 17.4375 16 L 21.21875 12.21875 L 19.78125 10.78125 L 16 14.5625 Z',
    circleError:
        // viewBoxSize = 32x32
        'M12 0C5.38341 0 0 5.38341 0 12C0 18.6165 5.38341 24 12 24C18.6165 24 24 18.6165 24 12C24 5.38341 18.6165 0 12 0ZM12 1.84616C17.6177 1.84616 22.1539 6.38221 22.1539 12C22.1539 17.6177 17.6177 22.1539 12 22.1539C6.38221 22.1539 1.84616 17.6177 1.84616 12C1.84616 6.38221 6.38221 1.84616 12 1.84616ZM8.50961 7.18269L7.18269 8.50961L10.6731 12L7.18269 15.4904L8.50961 16.8173L12 13.3269L15.4904 16.8173L16.8173 15.4904L13.3269 12L16.8173 8.50961L15.4904 7.18269L12 10.6731L8.50961 7.18269Z',
    circleInfo:
        // viewBoxSize = 32x32
        'M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z',
    circlePlus:
        'M9.5 0C4.26472 0 0 4.26472 0 9.5C0 14.7353 4.26472 19 9.5 19C14.7353 19 19 14.7353 19 9.5C19 4.26472 14.7353 0 9.5 0ZM9.5 1.46154C13.9474 1.46154 17.5385 5.05258 17.5385 9.5C17.5385 13.9474 13.9474 17.5385 9.5 17.5385C5.05258 17.5385 1.46154 13.9474 1.46154 9.5C1.46154 5.05258 5.05258 1.46154 9.5 1.46154ZM8.76923 4.38462V8.76923H4.38462V10.2308H8.76923V14.6154H10.2308V10.2308H14.6154V8.76923H10.2308V4.38462H8.76923Z',
    delete: 'M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z',
    download:
        'M 7 2 L 7 9.292969 L 4.351563 6.648438 L 3.648438 7.351563 L 7.5 11.207031 L 11.355469 7.351563 L 10.644531 6.648438 L 8 9.292969 L 8 2 Z M 3 13 L 3 14 L 12 14 L 12 13 Z',
    edit: 'M 12.03125 2.023438 C 11.535156 2.023438 11.066406 2.269531 10.675781 2.65625 L 2.5625 10.726563 L 1.207031 14.785156 L 5.265625 13.433594 L 5.351563 13.351563 L 13.386719 5.367188 C 13.773438 4.976563 14.015625 4.507813 14.015625 4.011719 C 14.015625 3.515625 13.773438 3.046875 13.386719 2.65625 C 12.996094 2.269531 12.527344 2.023438 12.03125 2.023438 Z M 10.027344 4.710938 L 11.320313 6.007813 L 4.726563 12.5625 L 2.789063 13.207031 L 3.4375 11.265625 Z',
    edit_property:
        'M2.5 2C1.677 2 1 2.677 1 3.5v8c0 .823.677 1.5 1.5 1.5H7v-1H2.5a.493.493 0 01-.5-.5V5h12v2h1V3.5c0-.823-.677-1.5-1.5-1.5h-11zm0 1h11c.281 0 .5.219.5.5V4H2v-.5c0-.281.219-.5.5-.5zM4 7v1h1V7H4zm2 0v1h6V7H6zm8.205.965c-.326.002-.651.129-.894.379l-4.665 4.742-.695 2.926 2.926-.698.098-.097 4.644-4.565c.5-.486.505-1.302.012-1.795l-.526-.525a1.259 1.259 0 00-.9-.367zm.006.994c.067 0 .134.027.187.08l.526.525a.253.253 0 01-.002.372l-.002.002-4.547 4.47-1.074.256.256-1.076 4.47-4.547a.26.26 0 01.186-.082zM4 9v1h1V9H4zm2 0v1h4V9H6z',
    error: 'M 7.5 1.007813 C 6.957031 1.007813 6.414063 1.265625 6.105469 1.78125 L 0.226563 11.605469 C -0.398438 12.644531 0.402344 14 1.617188 14 L 13.378906 14 C 14.597656 14 15.398438 12.644531 14.777344 11.605469 L 8.894531 1.78125 C 8.585938 1.265625 8.042969 1.007813 7.5 1.007813 Z M 7.5 1.992188 C 7.707031 1.992188 7.914063 2.09375 8.039063 2.296875 L 13.917969 12.117188 C 14.164063 12.527344 13.890625 13 13.378906 13 L 1.617188 13 C 1.109375 13 0.835938 12.527344 1.082031 12.117188 L 6.960938 2.296875 C 7.085938 2.09375 7.292969 1.992188 7.5 1.992188 Z M 6.992188 5 L 6.992188 10 L 7.992188 10 L 7.992188 5 Z M 6.992188 11 L 6.992188 12 L 7.992188 12 L 7.992188 11 Z',
    high_importance:
        'M 7.5 1 C 3.917969 1 1 3.917969 1 7.5 C 1 11.082031 3.917969 14 7.5 14 C 11.082031 14 14 11.082031 14 7.5 C 14 3.917969 11.082031 1 7.5 1 Z M 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 4.457031 13 2 10.542969 2 7.5 C 2 4.457031 4.457031 2 7.5 2 Z M 7 4 L 7 9 L 8 9 L 8 4 Z M 7 10 L 7 11 L 8 11 L 8 10 Z',
    info: 'M 7.5 1 C 3.917969 1 1 3.917969 1 7.5 C 1 11.082031 3.917969 14 7.5 14 C 11.082031 14 14 11.082031 14 7.5 C 14 3.917969 11.082031 1 7.5 1 Z M 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 4.457031 13 2 10.542969 2 7.5 C 2 4.457031 4.457031 2 7.5 2 Z M 7 4 L 7 5 L 8 5 L 8 4 Z M 7 6 L 7 11 L 8 11 L 8 6 Z',
    inspection:
        'm7.5 1c-.648437 0-1.199219.421875-1.40625 1h-2.59375c-.824219 0-1.5.675781-1.5 1.5v9c0 .824219.675781 1.5 1.5 1.5h8c.824219 0 1.5-.675781 1.5-1.5v-9c0-.824219-.675781-1.5-1.5-1.5h-2.59375c-.207031-.578125-.757812-1-1.40625-1zm0 1c.28125 0 .5.21875.5.5v.5h1v1h-3v-1h1v-.5c0-.28125.21875-.5.5-.5zm-4 1h1.5v2h5v-2h1.5c.28125 0 .5.21875.5.5v9c0 .28125-.21875.5-.5.5h-8c-.28125 0-.5-.21875-.5-.5v-9c0-.28125.21875-.5.5-.5zm7 3.042969-3.5 3.5-1.148437-1.144531-.351563-.355469-.707031.707031.355469.351563 1.851562 1.855468 4.207031-4.207031z',
    letterbox:
        'M 9 1 L 9 2 L 3.863281 2 C 2.285156 2 1 3.300781 1 4.886719 L 1 13 L 8 13 L 8 15 L 9 15 L 9 13 L 15 13 L 15 4.886719 C 15 3.300781 13.714844 2 12.136719 2 L 12 2 L 12 3 L 12.136719 3 C 13.171875 3 14 3.835938 14 4.886719 L 14 12 L 7 12 L 7 4.886719 C 7 4.164063 6.722656 3.507813 6.28125 3 L 9 3 L 9 6 L 10 6 L 10 2 L 11 2 L 11 1 Z M 3.863281 3 L 4.136719 3 C 5.171875 3 6 3.835938 6 4.886719 L 6 12 L 2 12 L 2 4.886719 C 2 3.835938 2.828125 3 3.863281 3 Z M 3 6 L 3 7 L 5 7 L 5 6 Z',
    long_arrow_left:
        'M 5.265625 4.03125 L 1.9375 7.492188 L 5.132813 10.964844 L 5.867188 10.285156 L 3.773438 8.007813 L 15 8 L 15 7 L 3.789063 7.007813 L 5.984375 4.71875 Z',
    minus_math: 'M 1 7 L 1 8 L 14 8 L 14 7 Z',
    puzzled:
        'm2.5 1c-.824219 0-1.5.675781-1.5 1.5h1c0-.28125.21875-.5.5-.5s.5.21875.5.5c0 .203125-.117187.371094-.285156.453125-.375.175781-.714844.5625-.714844 1.058594v.988281h1v-.988281c0-.050781.027344-.097656.148438-.15625v-.003906c.503906-.238282.851562-.757813.851562-1.351563 0-.824219-.675781-1.5-1.5-1.5zm5 0c-.957031 0-1.863281.214844-2.679687.585938.113281.285156.179687.589843.179687.914062 0 .039063-.011719.074219-.015625.113281.757813-.390625 1.609375-.613281 2.515625-.613281 3.03125 0 5.5 2.46875 5.5 5.5s-2.46875 5.5-5.5 5.5c-2.863281 0-5.21875-2.199219-5.476562-5h-1c.257812 3.351563 3.0625 6 6.476562 6 3.585938 0 6.5-2.914062 6.5-6.5 0-3.585937-2.914062-6.5-6.5-6.5zm-2.5 4.023438c-.550781-.003907-1 .664062-1 1.488281 0 .820312.449219 1.488281 1 1.488281s1-.667969 1-1.488281c0-.824219-.449219-1.492188-1-1.488281zm5 0c-.550781-.003907-1 .664062-1 1.488281 0 .820312.449219 1.488281 1 1.488281s1-.667969 1-1.488281c0-.824219-.449219-1.492188-1-1.488281zm-8 .976562v1h1v-1zm5.5 3c-1.125 0-1.890625.398438-2.347656.820313s-.625.890625-.625.890625l.945312.328125s.070313-.21875.359375-.484375c.289063-.265625.769532-.554688 1.667969-.554688h2.5v-1z',
    plus_math: 'M 7 1 L 7 7 L 1 7 L 1 8 L 7 8 L 7 14 L 8 14 L 8 8 L 14 8 L 14 7 L 8 7 L 8 1 Z',
    right_arrow:
        'M 11.734375 4.03125 L 11.015625 4.71875 L 13.210938 7.007813 L 2 7.007813 L 2 8.007813 L 13.191406 8.007813 L 11.015625 10.28125 L 11.734375 10.96875 L 15.066406 7.5 Z',
}
