import { tx } from '@transifex/native'
import { ApolloProvider } from '@apollo/client'
import { MockedProvider } from '@apollo/client/testing'
import { GlobalStyle } from 'styles/global-styles'
import { client } from 'apolloClient/config'
import { FeatureFlagsProvider } from 'components/FeatureFlags/FeatureFlags'
import { FEATURE_FLAGS } from 'index.constants'

import { ThemeProvider } from '@rg/common'

/**
 * This is for development purposes. Add mocked responses to the mocks array in the MockedProvider as needed
 * When developing, make sure REACT_APP_USE_APOLLO_MOCKS env var is set to true
 * Before commiting, make sure mocks are commented out so they're not added to the build (they might for instance rely on faker and bloat the build)
 */
export const ConditionalApolloProvider = ({ children }: { children: React.ReactNode }) => {
    if (process.env.REACT_APP_USE_APOLLO_MOCKS === 'true') {
        // eslint-disable-next-line no-console
        console.warn('*** APOLLO MOCKED PROVIDER ***')
        return (
            <MockedProvider
                mocks={
                    [
                        // closingOnboardingUserMockedResponse,
                        // standardReferralMockedResponse
                    ]
                }
            >
                <>{children}</>
            </MockedProvider>
        )
    }
    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

const TransifexProvider = ({ children }: { children: React.ReactNode }) => {
    const token = process.env.REACT_APP_TRANSIFEX_READ_KEY

    tx.init({
        token,
    })

    tx.setCurrentLocale('en_GB')

    return <>{children}</>
}

export const Providers = ({ children }: { children: React.ReactNode }) => (
    <ConditionalApolloProvider>
        <ThemeProvider>
            <TransifexProvider>
                <GlobalStyle />
                <FeatureFlagsProvider flags={FEATURE_FLAGS}>{children}</FeatureFlagsProvider>
            </TransifexProvider>
        </ThemeProvider>
    </ConditionalApolloProvider>
)
