import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

export const RangeInput = styled.input<{ backgroundSize: string }>(
    ({ theme, backgroundSize }) => css`
        /* Base CSS */
        appearance: none;
        width: 100%;
        border-radius: 8px;
        background: ${theme.palette.greyscale.main['10']};
        background-image: linear-gradient(#452159, #452159);
        background-size: ${backgroundSize}% 100%;
        background-repeat: no-repeat;

        /* Thumb Styles */
        ::-webkit-slider-thumb {
            appearance: none;
            box-sizing: content-box;
            border: 3px solid ${theme.palette.white};
            height: 26px;
            width: 26px;
            margin-top: -10px;
            border-radius: 50%;
            background: ${onboardingStyles.colors.purple};
            filter: drop-shadow(0 2px 2px rgb(0 0 0 / 30%));
            cursor: pointer;
        }

        ::-webkit-slider-thumb:active {
            background: #673082;
        }

        ::-moz-range-thumb {
            height: 26px;
            width: 26px;
            border: 3px solid ${theme.palette.white};
            border-radius: 50%;
            background: ${onboardingStyles.colors.purple};
            box-shadow:
                1px 1px 1px #000,
                0 0 1px #0d0d0d;
            cursor: pointer;
        }

        ::-moz-range-thumb:active {
            background: #673082;
        }

        ::-ms-thumb {
            height: 26px;
            width: 26px;
            border-radius: 50%;
            background: ${onboardingStyles.colors.purple};
            box-shadow:
                1px 1px 1px #000,
                0 0 1px #0d0d0d;
            cursor: pointer;
        }

        ::-ms-thumb:active {
            background: #673082;
        }

        /* Track Styles */
        ::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            border-radius: 8px;
            background: transparent;
            cursor: pointer;
        }

        ::-moz-range-track {
            width: 100%;
            height: 10px;
            border-radius: 8px;
            cursor: pointer;
        }

        ::-ms-track {
            width: 100%;
            height: 10px;

            /* Hides the slider so custom styles can be added */
            background: transparent;
            border-color: transparent;
            color: transparent;
            cursor: pointer;
        }
    `
)
