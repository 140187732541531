import { useContext, useState } from 'react'

import { ThemeContext } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Wrapper } from '@rg/patternlab'

import { RatingProps } from './Rating.types'
import { RatingItem } from './components/RatingItem'

export const Rating = ({ options, selectedRatingValue, setSelectedRatingValue }: RatingProps) => {
    const theme = useContext(ThemeContext)
    const [selectedIndex, setSelectedIndex] = useState<number>(-1)
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1)

    const onClick = (index: number) => {
        setSelectedIndex(index)
        setSelectedRatingValue(options[index].value)
    }

    const onMouseOver = (index: number) => setHoveredIndex(index)
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === ' ') {
            const targetIndex = options.findIndex((element) => element.label === e.currentTarget.value)
            setSelectedIndex(targetIndex)
            setSelectedRatingValue(options[targetIndex].value)
        }
    }

    const selectedLabel: string = selectedIndex >= 0 ? options[selectedIndex].label : ''
    const hoveredLabel: string = hoveredIndex >= 0 ? options[hoveredIndex].label : ''

    return (
        <Wrapper mb={theme.space.sm}>
            <Wrapper
                role="radiogroup"
                display="flex"
                my={theme.space.sm}
                onMouseOut={() => setHoveredIndex(selectedIndex)}
            >
                {options.map((item, index) => (
                    <RatingItem
                        key={item.label + item.value}
                        checked={selectedRatingValue === item.value}
                        {...{ item, index, onClick, onKeyDown, onMouseOver }}
                    />
                ))}
            </Wrapper>
            <Wrapper
                minHeight="40px"
                fontSize={theme.fontSizes.md}
                fontWeight="bold"
                color={onboardingStyles.colors.lightPurple}
            >
                {hoveredLabel ?? selectedLabel}
            </Wrapper>
        </Wrapper>
    )
}
