import { useMedia } from 'hooks/useMedia'
import discountsBgMobile from 'images/discounts_background_mobile.png'
import discountsBgDesktop from 'images/discounts_background.png'

import { useTheme } from '@rg/common'

import { CenteredImage, GradientBackground } from './Background.styles'
import { ImageWithGradientBackgroundProps } from './Background.types'

export { FixedImageBackground, GradientBackground } from './Background.styles'

export const ImageWithGradientBackground = ({ height, src, width, paddingTop }: ImageWithGradientBackgroundProps) => {
    const theme = useTheme()

    return (
        <GradientBackground height={height} paddingTop={paddingTop ?? theme.space.none}>
            <CenteredImage src={src} alt="Reward Gateway" width={width} />
        </GradientBackground>
    )
}

export const DiscountsBackground: React.FC = () => {
    const theme = useTheme()
    const isSM: boolean = useMedia(`(min-width: ${theme.breakpoints.md})`)

    return isSM ? (
        <ImageWithGradientBackground paddingTop={100} height={247} src={discountsBgDesktop} width={998} />
    ) : (
        <ImageWithGradientBackground paddingTop={50} height={123} src={discountsBgMobile} width={499} />
    )
}
