import { useEffect, useMemo, useState } from 'react'

import { nanoid } from 'nanoid'

import { RadioInputProps, StyledInput } from './index'

export const RadioInput: React.FC<RadioInputProps> = ({
    checked = false,
    name,
    onChange,
    id,
    testId,
    value,
    ...props
}) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked)
    const generatedId = useMemo(() => nanoid(), [])

    useEffect(() => setIsChecked(checked), [checked])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked)
        if (onChange) {
            onChange(e)
        }
    }

    return (
        <StyledInput
            aria-checked={isChecked}
            data-testid={testId || name}
            type="radio"
            name={name}
            value={value}
            id={id ?? generatedId}
            checked={isChecked}
            onChange={handleChange}
            {...props}
        />
    )
}
