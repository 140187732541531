import { ApolloError, useQuery } from '@apollo/client'
import { useLanguageContext } from 'context/Language/LanguageContext'

import { GET_REFERRAL } from '@rg/apollo/services/referrals/referrals.queries'
import { Query as GetReferralQuery } from '@rg/apollo/services/referrals/referrals.types'

type UseReferralArgs = {
    referralCode: string | undefined | null
    onCompleted?: (data: GetReferralQuery) => void
    onInvalid?: (data: GetReferralQuery) => void
}

export const useReferral = ({ referralCode, onCompleted, onInvalid }: UseReferralArgs) => {
    const { language } = useLanguageContext()

    return useQuery<GetReferralQuery>(GET_REFERRAL, {
        skip: !referralCode || !language.code,
        variables: {
            hash: referralCode as string,
            languageCode: `?languageCode=${language.code}`,
        },
        onCompleted: (data) => {
            if (onCompleted) {
                onCompleted(data)
            }
            if (!data?.referral && onInvalid) {
                onInvalid(data)
            }
        },
        onError: (error: ApolloError) => {
            // eslint-disable-next-line no-console
            console.log(error)
        },
    })
}
