import { useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Icon, Wrapper } from '@rg/patternlab'

import { getStepperStyles } from './helpers'
import { Circle, Label } from './Stepper.styles'
import { Size, StepperItemProps } from './Stepper.types'

export const StepperItem = ({
    size = Size.Large,
    isCurrent = false,
    label,
    stepNumber,
    totalStepsNumber,
}: StepperItemProps) => {
    const theme = useContext(ThemeContext)
    const stepperStyles = getStepperStyles(theme)
    const showLabel = Object.is(size, Size.Large) || (isCurrent && !Object.is(size, Size.Small))

    return (
        <Wrapper display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Circle stepperStyles={stepperStyles} isCurrent={isCurrent} size={size} mx={stepperStyles[size].mx}>
                {stepNumber}
            </Circle>
            {showLabel && (
                <Label
                    display="inline"
                    mx={stepperStyles[size].mx}
                    fontWeight={isCurrent ? 600 : 500}
                    fontSize={stepperStyles[size].labelFontSize}
                >
                    {label}
                </Label>
            )}

            {stepNumber !== totalStepsNumber && (
                <Icon
                    color={onboardingStyles.colors.yellow}
                    size={stepperStyles[size].iconSize}
                    icon="long_arrow_right"
                    mx={stepperStyles[size].mx}
                />
            )}
        </Wrapper>
    )
}
