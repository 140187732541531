import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

export const StyledErrorMessage = styled.div(
    ({ theme }) => css`
        font-size: ${theme.fontSizes.sm};
        font-weight: 400;
        color: ${onboardingStyles.colors.red};
        padding-top: ${theme.space.sm};
        text-align: left;
    `
)
