import styled, { css } from 'styled-components'
import { LayoutProps, SpaceProps, compose, space } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

export const StyledTextarea = styled.div<LayoutProps & SpaceProps>(
    ({ theme }) => css`
        padding: ${theme.space.md} 25px;
        color: ${theme.palette.greyscale.main['80']};
        font-family: ${onboardingStyles.font.family};
        font-size: ${theme.fontSizes.sm};
        background: ${theme.palette.white};
        box-shadow: ${onboardingStyles.shadows.mainBox};
        width: '100%';

        /* hide label while having it readable by screen readers */
        label {
            position: absolute;
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }

        textarea {
            font-family: ${onboardingStyles.font.family};
            display: block;
            border: none;
            background: transparent;
            resize: none;
            width: 100%;
            height: 100px;
        }

        && {
            ${compose(space)}
        }
    `
)
