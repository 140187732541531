import { globalAny } from 'index.constants'

export const scrollToTop = () => {
    try {
        // new API
        globalAny.window.scroll({
            top: 0,
            left: 0,
        })
    } catch (error) {
        //fallback for older browsers
        globalAny.window.scrollTo(0, 0)
    }
}
