import styled, { css } from 'styled-components'

import { Span } from '@rg/patternlab'

export const Ribbon = styled.div(
    () => css`
        width: 120px;
        height: 100%;
        min-height: 80px;
        max-height: 134px;
        background-color: #0051ba;
        position: relative;
        z-index: -1;
        color: #ffda1a;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 50% 90%, 0 100%, 0% 0%);
        margin-top: -35px;
    `
)

export const RibbonText = styled(Span)(
    ({ theme }) => css`
        && {
            padding: ${theme.space.none} ${theme.space.xs};
            margin: ${theme.space.lg} ${theme.space.none} ${theme.space.md};
            font-size: ${theme.fontSizes.xs};
            text-align: center;
            word-break: break-word;
            color: #ffda1a;

            ${theme.mediaQueries.md} {
                font-size: ${theme.fontSizes.sm};
            }

            ${theme.mediaQueries.lg} {
                font-size: ${theme.fontSizes.sm};
            }
        }
    `
)
