import { forwardRef } from 'react'

import { DisplayProps, SpaceProps } from 'styled-system'
import { Loading } from 'components/ui'

import { LoadingContainer, StyledTextButton } from './TextButton.styles'
import { TextButtonProps } from './TextButton.types'

const TextButton: React.FC<DisplayProps & SpaceProps & TextButtonProps> = forwardRef((props, ref) => {
    const { loading, children, ...otherProps } = props
    return (
        <StyledTextButton type="button" {...(loading && { disabled: true })} ref={ref} {...otherProps}>
            {children}
            {loading && (
                <LoadingContainer>
                    <Loading size={18} />
                </LoadingContainer>
            )}
        </StyledTextButton>
    )
})

TextButton.displayName = 'TextButton'

export { TextButton, StyledTextButton, LoadingContainer }
