import styled, { css } from 'styled-components'
import {
    BorderProps,
    DisplayProps,
    LayoutProps,
    SpaceProps,
    border,
    compose,
    display,
    layout,
    space,
} from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { ButtonComponentProps } from '@rg/patternlab/components/Button/Button.types'
import { Button } from '@rg/patternlab'

export const MainButton = styled(Button)<ButtonComponentProps & BorderProps & DisplayProps & LayoutProps & SpaceProps>(
    ({ theme }) => css`
        &&& {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${`${theme.space.md} ${theme.space.lg}`};
            margin-top: ${theme.space.ml};
            min-width: ${onboardingStyles.dimensions.buttonDefaultWidth};
            width: fit-content;
            max-width: 100%;
            font-size: ${theme.fontSizes.lg};
            font-family: ${onboardingStyles.font.family};
            font-weight: 600;
            color: ${onboardingStyles.elementColors.primaryBtnTxt};
            background-color: ${onboardingStyles.elementColors.primaryBtnBg};
            border: 1px solid ${onboardingStyles.elementColors.primaryBtnBg};
            border-radius: 999px;
            transition: background-color 0.15s ease-out;

            ${theme.mediaQueries.sm} {
                margin-top: 40px;
            }

            &:not(:disabled):active,
            &:not(:disabled):focus,
            &:not(:disabled):hover {
                background-color: ${onboardingStyles.elementColors.primaryBtnHoverBg};
            }

            &:not(:disabled):focus {
                box-shadow: ${onboardingStyles.shadows.focusBox};
            }

            &:disabled {
                cursor: auto;
                opacity: 0.5;
            }

            &::before {
                display: none;
            }

            && {
                ${compose(border, display, layout, space)}
            }
        }
    `
)
