import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useT } from '@transifex/react'
import { ThemeContext } from 'styled-components'
import { getSteps } from 'index.constants'
import { useMedia } from 'hooks/useMedia'
import { useOnboardingUser } from 'hooks/useOnboardingUser'
import { useReferral } from 'hooks/useReferral'
import { skipAllowed } from 'utils/rg'

import { Wrapper } from '@rg/patternlab'

import { Size, Step } from './Stepper.types'
import { StepperItem } from './StepperItem'
import { StepperLink, StepperWrapper } from './Stepper.styles'

export const Stepper = () => {
    const history = useHistory()
    const location = useLocation()
    const t = useT()
    const items = getSteps(t)
    const theme = useContext(ThemeContext)

    const isLarge = useMedia(`(min-width: ${theme.breakpoints.xl})`)
    const isMedium = useMedia(`(min-width: ${theme.breakpoints.sm})`)

    const [size, setSize] = useState(Size.Small)

    const { data: userData, loading: loadingUserData } = useOnboardingUser()
    const { data: referralData, loading: loadingReferralData } = useReferral({
        referralCode: userData?.onboardingUser?.referralCode,
    })

    useEffect(() => {
        let newSize = Size.Small
        if (isLarge) {
            newSize = Size.Large
        } else if (isMedium) {
            newSize = Size.Medium
        }
        setSize(newSize)
    }, [isLarge, isMedium])

    const allowSkip = skipAllowed(
        loadingUserData,
        loadingReferralData,
        userData?.onboardingUser,
        referralData?.referral
    )

    const steps: Step[] = items.map((item, key) => {
        const isOptionalBillingStep = allowSkip && key === 2
        const labelTextString = isOptionalBillingStep ? t('Set up billing (optional)') : item.labelTextString
        return {
            label: labelTextString,
            ...item,
        }
    })

    const currentStep: Step | undefined = steps.find((step: Step) =>
        Array.isArray(step.route) ? step.route.includes(location.pathname) : location.pathname === step.route
    )

    const currentStepIndex: number = (currentStep ? steps.indexOf(currentStep) : undefined) as number
    const currentStepReached = Number(localStorage.getItem('stepReached'))
    if (currentStepIndex > currentStepReached) {
        localStorage.setItem('stepReached', String(currentStepIndex))
    }

    return (
        <StepperWrapper>
            {steps.map((step: Step, index: number) => {
                const key = `${step.label}-${index}`
                const isCurrentStep = index === currentStepIndex
                const isPreviousStep = currentStepIndex ? index < currentStepIndex : undefined
                const hasLink = step.link && isPreviousStep

                return (
                    <Wrapper key={key} data-automation={step.automationid}>
                        {hasLink ? (
                            <StepperLink onClick={() => step.link && history.push(step.link)}>
                                <StepperItem
                                    label={step.label}
                                    stepNumber={index + 1}
                                    totalStepsNumber={steps.length}
                                    isCurrent={isCurrentStep}
                                    size={size}
                                />
                            </StepperLink>
                        ) : (
                            <StepperItem
                                label={step.label}
                                stepNumber={index + 1}
                                totalStepsNumber={items.length}
                                isCurrent={isCurrentStep}
                                size={size}
                            />
                        )}
                    </Wrapper>
                )
            })}
        </StepperWrapper>
    )
}
