import styled, { css } from 'styled-components'

import { Span } from '@rg/patternlab'

export const ScreenReaderOnlyStyles = css`
    position: absolute !important;
    left: -9999px !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    border: 0 !important;
    white-space: nowrap !important;
`

export const ScreenReaderOnlySpan = styled(Span)`
    ${ScreenReaderOnlyStyles};

    /* The Span from Patternlab has a preset color which is detected as an a11y issue by automated tools */
    && {
        color: inherit;
    }
`
