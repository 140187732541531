import { useT } from '@transifex/react'
import { OpensInANewTabSpan } from 'components/ScreenReaderOnly'

import { Link } from '@rg/patternlab'

import { FooterLinksWrapper } from './FooterLinks.styles'

export const FooterLinks = () => {
    const t = useT()

    return (
        <FooterLinksWrapper>
            <Link
                data-testid="footer-cookie-policy-link"
                href="https://www.rewardgateway.com/cookie-policy"
                target="_blank"
            >
                {t('Cookies Policy')}
                <OpensInANewTabSpan />
            </Link>
            <Link
                data-testid="footer-privacy-notice-link"
                href="https://www.rewardgateway.com/privacy-notice"
                target="_blank"
            >
                {t('Privacy Notice')}
                <OpensInANewTabSpan />
            </Link>
        </FooterLinksWrapper>
    )
}
