import { useEffect, useState } from 'react'

import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { toLowercaseWithDashes } from 'utils/helpers'

import { Wrapper } from '@rg/patternlab'
import { useTheme } from '@rg/common'

import { StyledLabel } from '../Radio/Radio.styles'
import { RadioInput } from '../Radio/RadioInput'
import { RadioAsButtonGroupProps } from './RadioAsButtonGroup.types'
import { RadioAsButtonFeature, RadioAsButtonGroupContainer, RadioAsButtonWrapper } from './index'

export const RadioAsButtonGroup = ({
    defaultOptionName,
    radioAsButtonOptions,
    containerBackgroundColor,
    onChange,
}: RadioAsButtonGroupProps) => {
    const theme = useTheme()
    const [checkedRadio, setCheckedRadio] = useState(defaultOptionName)

    const containerBackgroundColorWithFallback = containerBackgroundColor ?? theme.palette.greyscale.main['10']

    const handleRadioChange = (name: string) => {
        setCheckedRadio(name)

        if (onChange) {
            onChange(name)
        }
    }

    useEffect(() => {
        setCheckedRadio(defaultOptionName)
    }, [defaultOptionName])

    return (
        <RadioAsButtonGroupContainer
            role="radiogroup"
            data-testid="radio-as-button-group-container"
            backgroundColor={containerBackgroundColorWithFallback}
        >
            {radioAsButtonOptions.map(({ name, label, testId, radioAsButtonFeature }) => {
                const checked = checkedRadio === name
                const labelWithDashes = toLowercaseWithDashes(label)
                return (
                    <RadioAsButtonWrapper
                        data-testid="radio-as-button-wrapper"
                        tabIndex={0}
                        key={`radio-as-button-wrapper-${labelWithDashes}`}
                        onClick={() => handleRadioChange(name)}
                        onKeyDown={({ keyCode }) => keyCode === 32 && handleRadioChange(name)}
                        backgroundColor={
                            checked ? onboardingStyles.colors.yellow : containerBackgroundColorWithFallback
                        }
                    >
                        {radioAsButtonFeature && <RadioAsButtonFeature>{radioAsButtonFeature}</RadioAsButtonFeature>}
                        <RadioInput
                            type="radio"
                            aria-labelledby={`radio-input-label-${labelWithDashes}`}
                            id={labelWithDashes}
                            testId={testId}
                            name={name}
                            value={name}
                            checked={checked}
                            onChange={() => handleRadioChange(name)}
                        />
                        <Wrapper display="flex" width="100%" justifyContent="center" alignItems="center">
                            <StyledLabel htmlFor={labelWithDashes} id={`radio-input-label-${labelWithDashes}`}>
                                {label}
                            </StyledLabel>
                        </Wrapper>
                    </RadioAsButtonWrapper>
                )
            })}
        </RadioAsButtonGroupContainer>
    )
}
