import { useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { useT } from '@transifex/react'
import { MainButton, ModalTriggerButton } from 'components/ui'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { P, TBody, Wrapper } from '@rg/patternlab'

import { StyledModal } from '../Modal/Modal.styles'
import { TD, TH, TR, Table } from './CompanyNumberModal.styles'

export const CompanyNumberModal = () => {
    const t = useT()
    const theme = useContext(ThemeContext)
    return (
        <StyledModal
            maxWidth="980px"
            width={{ default: '100%', md: '980px' }}
            ariaLabel="Company registration number examples modal"
            trigger={<ModalTriggerButton>{t('See some examples')}</ModalTriggerButton>}
            header={
                <P color={onboardingStyles.colors.darkBlue} size="lg" fontWeight={600}>
                    {t('How does a Company Registration Number look like?')}
                </P>
            }
            footer={({ setIsOpen }) => (
                <Wrapper display="flex" justifyContent="center">
                    <MainButton onClick={() => setIsOpen(false)}>{t('Close')}</MainButton>
                </Wrapper>
            )}
        >
            <Table>
                <TBody>
                    <TR>
                        <TH>{t('Registered office jurisdiction')}</TH>
                        <TH>{t('Example Company Registration Numbers')}</TH>
                    </TR>
                    <TR>
                        <TD bg={theme.palette.greyscale.main['40']}>{t('England and Wales')}</TD>
                        <TD>
                            {t('01234567 (Limited Company)')} <br />
                            {t('OC555555 (LLP)')} <br />
                            {t('LP003139 (Limited Partnership)')}
                        </TD>
                    </TR>
                    <TR>
                        <TD bg={theme.palette.greyscale.main['40']}>{t('Scotland')}</TD>
                        <TD>
                            {t('SC123456 (Limited Company)')} <br />
                            {t('SO305443 (LLP)')} <br />
                            {t('SL002900 (Limited Partnership)')}
                        </TD>
                    </TR>
                    <TR>
                        <TD bg={theme.palette.greyscale.main['40']}>{t('Northern Ireland')}</TD>
                        <TD>
                            {t('NI123456 (Limited Company)')} <br />
                            {t('R0000284 (Older Limited Company)')} <br />
                            {t('NC001306 (LLP)')} <br />
                            {t('NL000107 (Limited Partnership)')}
                        </TD>
                    </TR>
                </TBody>
            </Table>
            <P
                color={onboardingStyles.colors.darkBlue}
                size="lg"
                fontWeight={600}
                mt={theme.space.ml}
                mb={theme.space.ml}
            >
                {t('You can find you company registration number in several places:')}
            </P>
            <ul>
                <li>
                    {t(`On the company's certificate of incorporation. The company name and company number are both
                    shown on the incorporation certificate issued by Companies House.`)}
                </li>
                <li>{t('Any official letters or other documentation received from Companies House.')}</li>
                <li>
                    {t(
                        `Using Inform Direct's company search tool. If you type in your company name, the company number will be shown in brackets. (You don't have to register, but if you do, you'll gain the use of our acclaimed company record management tool!)`
                    )}
                </li>
                <li>
                    {t('On the public register. You can search ')}
                    <a href="https://find-and-update.company-information.service.gov.uk">
                        {t(`Companies House's records`)}
                    </a>
                    {t(' by company name.')}
                </li>
            </ul>
        </StyledModal>
    )
}
