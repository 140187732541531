import { useMemo } from 'react'

import { flexbox, layout, space } from 'styled-system'
import { pick, reject } from 'utils/helpers'

export function getSpaceLayoutProps(props: Record<string, any>): Record<string, any>[] {
    const keys = [...(space.propNames || []), ...(layout.propNames || []), ...(flexbox.propNames || [])]
    const pickedProps = pick(props, keys)
    const rejectedProps = reject(props, keys)

    return [pickedProps, rejectedProps]
}

export const useSpaceLayoutProps = (props: Record<string, any>) => useMemo(() => getSpaceLayoutProps(props), [props])
