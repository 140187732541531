import styled, { css } from 'styled-components'
import {
    HeightProps,
    PaddingProps,
    ResponsiveValue,
    WidthProps,
    ZIndexProps,
    compose,
    height,
    padding,
    system,
    variant,
    width,
    zIndex,
} from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { WhiteSpace } from '../Typography/Typography.types'
import { PopupProps } from './Popup.types'

// Only use Top, Left and Transform properties for placement, so that when using responsive variants the media queries are able to correctly override previous placement styles from the previous mobile-first media queries.

export const PopupArrow = styled.span<Required<Pick<PopupProps, 'placement'>> & ZIndexProps>(({ theme }) => {
    const topArrowStyles = {
        top: `calc(-${theme.space.md} - ${theme.space.sm})`,
        left: '50%',
        transform: 'translateX(-50%)',
    }

    const rightArrowStyles = {
        top: '50%',
        left: `calc(100% + ${theme.space.sm})`,
        transform: 'translateY(-50%)',
    }

    const bottomArrowStyles = {
        top: `calc(100% + ${theme.space.sm})`,
        left: '50%',
        transform: 'translateX(-50%)',
    }

    const leftArrowStyles = {
        top: '50%',
        left: `calc(-${theme.space.md} - ${theme.space.sm})`,
        transform: 'translateY(-50%)',
    }

    return css`
        width: ${theme.space.md};
        height: ${theme.space.md};
        position: absolute;
        display: block;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 100%;
            height: 100%;
            background-color: ${theme.palette.white};
            transform: translate(-50%, -50%) rotate(45deg);
        }

        ${variant({
            prop: 'placement',
            variants: {
                top: topArrowStyles,
                topRight: topArrowStyles,
                topLeft: topArrowStyles,
                right: rightArrowStyles,
                rightTop: rightArrowStyles,
                rightBottom: rightArrowStyles,
                bottom: bottomArrowStyles,
                bottomRight: bottomArrowStyles,
                bottomLeft: bottomArrowStyles,
                left: leftArrowStyles,
                leftTop: leftArrowStyles,
                leftBottom: leftArrowStyles,
            },
        })}

        ${zIndex}
    `
})

export const PopupArrowShadow = styled(PopupArrow)(
    () => css`
        &::before {
            box-shadow: ${onboardingStyles.shadows.mainBox};
        }
    `
)

interface PopupBoxProps
    extends Required<Pick<PopupProps, 'placement'>>,
        WidthProps,
        HeightProps,
        PaddingProps,
        ZIndexProps {
    whiteSpace?: ResponsiveValue<WhiteSpace>
    isOpen: boolean
}

export const PopupBox = styled.div<PopupBoxProps>(
    ({ theme, isOpen }) => css`
        background-color: ${theme.palette.white};
        position: absolute;
        padding: ${theme.space.sm};
        box-shadow: ${onboardingStyles.shadows.mainBox};
        border-radius: 3px;
        display: block;
        ${!isOpen &&
        css`
            display: none;
            & ~ ${PopupArrow} {
                display: none;
            }
        `}

        ${variant({
            prop: 'placement',
            variants: {
                top: {
                    top: `-${theme.space.md}`,
                    left: '50%',
                    transform: 'translate(-50%, -100%)',
                },
                topRight: {
                    top: `-${theme.space.md}`,
                    left: `calc(50% - ${theme.space.md} - ${theme.space.sm})`,
                    transform: 'translateY(-100%)',
                },
                topLeft: {
                    top: `-${theme.space.md}`,
                    left: `calc(50% + ${theme.space.md} + ${theme.space.sm})`,
                    transform: 'translate(-100%,-100%)',
                },
                right: {
                    top: '50%',
                    left: `calc(100% + ${theme.space.md})`,
                    transform: 'translateY(-50%)',
                },
                rightTop: {
                    left: `calc(100% + ${theme.space.md})`,
                    top: `calc(50% + ${theme.space.md} + ${theme.space.sm})`,
                    transform: `translateY(-100%)`,
                },
                rightBottom: {
                    top: `-${theme.space.sm}`,
                    left: `calc(100% + ${theme.space.md})`,
                    transform: `none`,
                },
                bottom: {
                    top: `calc(100% + ${theme.space.md})`,
                    left: '50%',
                    transform: 'translateX(-50%)',
                },
                bottomRight: {
                    top: `calc(100% + ${theme.space.md})`,
                    left: `calc(50% - ${theme.space.md} - ${theme.space.sm})`,
                    transform: 'none',
                },
                bottomLeft: {
                    top: `calc(100% + ${theme.space.md})`,
                    left: `calc(50% + ${theme.space.md} + ${theme.space.sm})`,
                    transform: `translateX(-100%)`,
                },
                left: {
                    top: '50%',
                    left: `-${theme.space.md}`,
                    transform: 'translate(-100%, -50%)',
                },
                leftTop: {
                    top: `calc(50% + ${theme.space.md} + ${theme.space.sm})`,
                    left: `-${theme.space.md}`,
                    transform: 'translate(-100%, -100%)',
                },
                leftBottom: {
                    top: `-${theme.space.sm}`,
                    left: `-${theme.space.md}`,
                    transform: 'translate(-100%, 0%)',
                },
            },
        })}

        ${compose(padding, width, height, zIndex)}
        ${system({
            whiteSpace: true,
        })}
    `
)

export const PopupWrapper = styled.div<ZIndexProps>`
    position: relative;
    display: inline-block;
    ${zIndex}
`
