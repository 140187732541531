/**
 * Makes the language name bold.
 *
 * @param {string} languageName - The language name to be made bold. Expected formats: "Language (Country)" or "Language".
 * @returns {string} The language name with bold formatting.
 */

export const makeLanguageBold = (languageName: string): JSX.Element => {
    const stringArr = languageName.split('(')
    const language = stringArr[0].trim()
    const country = stringArr[1]

    if (!country) {
        return <strong>{language}</strong>
    } else {
        return (
            <>
                <strong>{language} </strong>&nbsp;({country}
            </>
        )
    }
}
