import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import * as zxcvbnFrPackage from '@zxcvbn-ts/language-fr'
import * as zxcvbnNlBePackage from '@zxcvbn-ts/language-nl-be'
import { useT } from '@transifex/react'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { useLanguageContext } from 'context/Language/LanguageContext'

import { useTheme } from '@rg/common'

import { ProgressBar, ProgressLabel } from './PasswordStrengthMeter.styles'

export type StrengthValue = {
    label: string
    color: string
}

export const PasswordStrengthMeter = ({ password, isValid }: { password: string; isValid: boolean }) => {
    const t = useT()
    const theme = useTheme()

    const { language } = useLanguageContext()

    let passwordDictionary = {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
    }

    switch (language.code) {
        case 'fr_BE':
            passwordDictionary = {
                ...passwordDictionary,
                ...zxcvbnFrPackage.dictionary,
            }
            break
        case 'nl_BE':
            passwordDictionary = {
                ...passwordDictionary,
                ...zxcvbnNlBePackage.dictionary,
                firstnames: [
                    ...zxcvbnNlBePackage.dictionary.boysFirstnames,
                    ...zxcvbnNlBePackage.dictionary.girlsFirstnames,
                ], // type discrepancy between EN/FR and NL_BE
            }
            break
    }

    const options = {
        translations: zxcvbnEnPackage.translations,
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: passwordDictionary,
    }

    zxcvbnOptions.setOptions(options)

    const { score } = zxcvbn(password)
    const finalScore = isValid ? Math.max(score, 2) : Math.min(score, 1)
    const strengthValues: StrengthValue[] = [
        { label: t('Very Weak'), color: onboardingStyles.colors.red },
        { label: t('Weak'), color: onboardingStyles.colors.yellow },
        { label: t('Medium'), color: onboardingStyles.colors.lightBlue },
        { label: t('Strong'), color: theme.palette.primary.main['100'] },
        { label: t('Very Strong'), color: theme.palette.primary.main['120'] },
    ]

    return (
        <>
            {password && (
                <ProgressLabel data-testid="password-strength-meter-result" htmlFor="password-strength-bar">
                    {`${t('Password strength')}:`}
                    <ProgressBar
                        id="password-strength-bar"
                        backgroundColor={strengthValues[finalScore].color}
                        value={finalScore}
                        max="4"
                    />
                    <strong>{strengthValues[finalScore].label}</strong>
                </ProgressLabel>
            )}
        </>
    )
}
