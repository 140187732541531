import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { NumberInput } from '../NumberInput/NumberInput'

export const StyledWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 82px;
        height: 68px;
        padding: ${theme.space.sm};
        border-radius: 10px;
        background-color: ${theme.palette.greyscale.main['10']};
        filter: drop-shadow(0 3px 2px rgb(0 0 0 / 30%));
    `
)

export const StyledNumberInput = styled(NumberInput)<{ withMaxValueAdditionalSign: boolean }>(
    ({ theme, withMaxValueAdditionalSign }) => css`
        width: 71px;
        height: 34px;
        padding: ${withMaxValueAdditionalSign
            ? `${theme.space.xs} ${theme.space.md} ${theme.space.xs} ${theme.space.xs}`
            : theme.space.xs};
        margin-bottom: ${theme.space.xxs};
        border-radius: 7px;
        font-size: ${theme.fontSizes.xxl};
        font-weight: ${theme.fontWeights.bold};
        text-align: center;
        background-color: ${theme.palette.white};
        color: ${onboardingStyles.colors.purple};

        /* Hide up/down input arrows */
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            appearance: none;
            margin: ${theme.space.none};
        }

        /* Firefox */
        appearance: textfield;
    `
)

export const MaxValueAdditionalSign = styled.div(
    ({ theme }) => css`
        position: absolute;
        right: 10px;
        width: 13px;
        height: 31px;
        margin: ${theme.space.xxs} ${theme.space.none};
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        font-size: ${theme.fontSizes.xl};
        font-weight: ${theme.fontWeights.bold};
        text-align: center;
        color: ${onboardingStyles.colors.purple};
        background-color: ${theme.palette.white};
    `
)

export const RoundedCounterStyledLabel = styled.label(
    ({ theme }) => css`
        overflow: hidden;
        font-size: ${theme.fontSizes.sm};
        color: ${onboardingStyles.colors.purple};
    `
)
