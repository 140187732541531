import styled, { css } from 'styled-components'
import { FlexboxProps, LayoutProps, compose, space } from 'styled-system'

import { Wrapper } from '@rg/patternlab'

export const Container = styled(Wrapper)<LayoutProps & FlexboxProps>(
    ({ theme }) => css`
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: ${theme.space.none} ${theme.space.md};
        display: flex;
        flex-direction: column;

        ${theme.mediaQueries.md} {
            padding: ${theme.space.none} ${theme.space.ml};
        }

        ${compose(space)}
    `
)

Container.defaultProps = {
    width: {
        default: '100%',
        md: '90%',
    },
}
