import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { Billing, useGetBillingEntity } from 'hooks/useGetBillingEntity'
import { bannerNotify } from 'components/ui'
import { SomethingWentWrongNotificationMessage } from 'components/ui/BannerNotification/SomethingWentWrongNotificationMessage'

import type { BillingContextType } from './billing.types'
import { getInitialBilling } from './billingHelpers'

const initialContextValue: BillingContextType = {
    billing: {} as Billing,
    setBilling: () => {},
    billingOptions: [] as Billing[],
    setBillingOptions: () => {},
    hasBillingModalBeenOpened: false,
    setHasBillingModalBeenOpened: () => {},
}

export const BillingContext = createContext<BillingContextType>(initialContextValue)

export const BillingContextProvider = ({ children }: { children: ReactNode }) => {
    const [billing, setBilling] = useState<Billing>({} as Billing)
    const [billingOptions, setBillingOptions] = useState<Billing[]>([])
    const [hasBillingModalBeenOpened, setHasBillingModalBeenOpened] = useState(false)
    const { data: billingData, loading: billingLoading, error: billingError } = useGetBillingEntity()
    const localeStorageBillingId = localStorage.getItem('billingId')

    useEffect(() => {
        if (localeStorageBillingId) {
            setBilling(getInitialBilling(billingOptions))
            setHasBillingModalBeenOpened(true)
        }
    }, [billingOptions, localeStorageBillingId])

    useEffect(() => {
        if (!billingLoading && billingData?.billingEntity) {
            setBillingOptions(billingData.billingEntity)
            setBilling(getInitialBilling(billingData.billingEntity))
        }
        if (!billingLoading && billingError) {
            setBilling(getInitialBilling([]))
            bannerNotify({
                id: 'network-error-notification',
                variant: 'error',
                message: <SomethingWentWrongNotificationMessage />,
            })
        }
    }, [billingData, billingError, billingLoading])

    const contextValue: BillingContextType = useMemo(() => {
        return {
            billingOptions,
            setBillingOptions,
            billing,
            setBilling,
            hasBillingModalBeenOpened,
            setHasBillingModalBeenOpened,
        }
    }, [billingOptions, hasBillingModalBeenOpened, billing])

    return <BillingContext.Provider value={contextValue}>{children}</BillingContext.Provider>
}

export const useBillingContext = (): BillingContextType => {
    const context = useContext(BillingContext)
    if (typeof context === 'undefined') {
        throw new Error('useBillingContext must be used within a BillingContextProvider')
    }
    return context
}
