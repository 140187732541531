import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { getStyledInputBackgroundColor, getStyledInputBorder } from 'utils/helpers'

import { StyledInputProps } from './InputFloatingLabel.types'

// Input mobile styles is quite different to Select mobile styles
const mobileXPadding = 16
const mobileYPadding = 12

// the sum of values below must be equal to value above * 2
const mobileTopPadding = 21
const mobileBottomPadding = 3

// todo: these 2 are same for Select, move to style-theme
const desktopXPadding = 20
const desktopYPadding = 16

const desktopTopPadding = 26
const desktopBottomPadding = 6

const mobilePrefixPadding = 42
const desktopPrefixPadding = 48

export const StyledInput: any = styled.input<StyledInputProps>(
    ({ disabled, hasError, short, prefix }) => css`
        display: block;
        flex: 1;
        box-shadow: none;
        font-size: ${({ theme }) => theme.fontSizes.lg};
        font-family: ${onboardingStyles.font.family};
        padding: ${mobileTopPadding}px ${mobileXPadding}px ${mobileBottomPadding}px
            ${prefix ? mobilePrefixPadding + mobileXPadding : mobileXPadding}px;

        ${({ theme }) => theme.mediaQueries.md} {
            padding: ${desktopTopPadding}px ${desktopXPadding}px ${desktopBottomPadding}px
                ${prefix ? desktopPrefixPadding + desktopXPadding : desktopXPadding}px;
        }

        width: 100%;
        max-width: ${short ? onboardingStyles.dimensions.shortInputMaxWidth : 'none'};
        border: 1px solid ${getStyledInputBorder(hasError, disabled)};
        background-color: ${({ theme }) => getStyledInputBackgroundColor(theme.palette.white, hasError, disabled)};
        color: ${({ theme }) => (disabled ? theme.palette.disabled.main['100'] : onboardingStyles.colors.darkBlue)};

        &:focus {
            border: 1px solid ${onboardingStyles.colors.lightBlue};
            box-shadow: ${onboardingStyles.shadows.focusBox};
            outline: none;
        }
    `
)

export const InputAppend = styled.div(
    ({ theme }) => css`
        color: ${onboardingStyles.colors.darkBlue};
        display: block;
        font-size: ${theme.fontSizes.lg};
        font-weight: 600;
        background-color: ${onboardingStyles.colors.lighterBlue};
        padding: ${theme.space.xs} ${mobileXPadding}px;

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.xl};
            margin-left: ${theme.space.sm};
            padding: ${mobileTopPadding - 2}px 0 0;
            background-color: transparent;
        }
    `
)

export const InputLabel = styled.div(
    ({ theme }) => css`
        padding: ${mobileYPadding}px ${mobileXPadding + 2}px;
        font-size: ${theme.fontSizes.md};
        font-family: ${onboardingStyles.font.family};
        opacity: 1; /* Firefox */
        position: absolute;
        pointer-events: none;
        color: ${theme.palette.greyscale.main['50']};

        > div {
            margin-top: ${theme.space.xs};
        }

        ${theme.mediaQueries.sm} {
            padding: ${desktopYPadding}px ${desktopXPadding + 2}px;
            font-size: ${theme.fontSizes.lg};

            > div {
                margin-top: ${theme.space.none};
            }
        }
    `
)

export const InputGroup = styled.div(
    ({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: left;

        /*
    - top margin so it's spaced from the field above
    - no bottom margin so it sits just above to the error/info message
  */
        margin: ${theme.space.sm} ${theme.space.none} ${theme.space.none};

        ${theme.mediaQueries.md} {
            flex-direction: row;
            margin: ${theme.space.md} ${theme.space.none} ${theme.space.none};
        }

        ${space}
    `
)

export const Prefix = styled.div(
    ({ theme }) => css`
        position: absolute;
        user-select: none;
        font-family: ${onboardingStyles.font.family};
        font-size: ${theme.fontSizes.md};

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.lg};
            padding-top: ${desktopTopPadding}px;
            padding-left: ${desktopXPadding}px;
        }

        color: ${theme.palette.greyscale.main['80']};
        padding-top: ${mobileTopPadding}px;
        padding-left: ${mobileXPadding}px;
    `
)
