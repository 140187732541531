import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { StyledCheckboxProps } from './Checkbox.types'

// icon
const strokeColor = onboardingStyles.colors.lightBlue
const strokeWidth = '3px'

export const Wrapper = styled.div(
    ({ theme }) => css`
        /*
    - top margin so it's spaced from the field above
    - no bottom margin so it sits just above to the error/info message
  */
        margin: ${theme.space.md} ${theme.space.none} ${theme.space.none};
        cursor: pointer;

        ${space}
    `
)

export const Label = styled.label`
    pointer-events: none;
`

export const LabelText = styled.span(
    ({ theme }) => css`
        margin-left: ${theme.space.sm};
    `
)

export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`

export const Icon = styled.svg`
    fill: none;
    stroke: ${strokeColor};
    stroke-width: ${strokeWidth};
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: ${({ theme }) => theme.space.none};
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
    display: inline-block;

    ${HiddenCheckbox}:focus + & {
        border: 1px solid ${onboardingStyles.colors.lightBlue};
        box-shadow: ${onboardingStyles.shadows.focusBox};
    }

    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }

    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.palette.white};
    transition: all 150ms;
    border: 1px solid ${onboardingStyles.colors.lighterBlue};
`
