export const getSliderBackgroundSize = (args: {
    min: string | number
    max: string | number
    value: number
}): string => {
    const [min, max, value] = Object.values(args).map(Number)
    const sliderBackgroundSize = ((value - min) * 100) / (max - min)

    return sliderBackgroundSize.toString()
}
