import { RatingItemProps } from '../Rating.types'
import { RatingItemWrapper } from '../Rating.styles'

export const RatingItem = ({ item, index, onClick, onMouseOver, onKeyDown, checked = false }: RatingItemProps) => {
    const { icon, label, value } = item
    const id = `${label}-${value}`

    return (
        <RatingItemWrapper>
            <input
                defaultChecked={checked}
                type="radio"
                name="rating"
                id={id}
                onKeyDown={onKeyDown}
                onClick={() => onClick(index)}
            />
            <label
                data-testid={`${label}-${value}`}
                aria-label={label}
                htmlFor={id}
                onMouseOver={() => onMouseOver(index)}
                onFocus={() => onMouseOver(index)}
            >
                {icon}
            </label>
        </RatingItemWrapper>
    )
}
