import { ChangeEvent, useEffect, useRef, useState } from 'react'

import { Wrapper } from '@rg/patternlab'

import {
    MaxValueAdditionalSign,
    RoundedCounterStyledLabel,
    StyledNumberInput,
    StyledWrapper,
} from './RoundedCounter.styles'
import { RoundedCounterProps } from './RoundedCounter.types'

export const RoundedCounter = ({
    label,
    minValue = 1,
    maxValue = 100,
    value,
    onChange,
    maxValueAdditionalSign,
}: RoundedCounterProps) => {
    const [count, setCount] = useState<number>(value)
    const inputRef = useRef<HTMLInputElement>(null)

    const hasReachedMax = count === maxValue
    const withMaxValueAdditionalSign = Boolean(hasReachedMax && maxValueAdditionalSign)

    const handleOnCountChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const numberInput = e.target
        const newValue = Number(numberInput.value)

        if (newValue >= minValue && newValue <= maxValue) {
            setCount(newValue)
            onChange(newValue)
        }
    }

    const handleOnCountBlur = (e: ChangeEvent<HTMLInputElement>): void => {
        const numberInput = e.target
        const newValue = numberInput.value

        if (typeof newValue === 'string' && newValue === '') {
            setCount(value)
            onChange(value)
            //handles edge case when user deletes the value and then clicks outside of the input
            e.target.value = value.toString()
            return
        }

        const newCount = Number(newValue)

        if (maxValue && newCount >= maxValue) {
            if (newCount === maxValue) {
                setCount(newCount)
                onChange(newCount)
            }

            if (newCount > maxValue) {
                setCount(maxValue)
                onChange(maxValue)
            }
        } else if (minValue && newCount < minValue) {
            setCount(minValue)
            onChange(minValue)
        } else {
            setCount(newCount)
            onChange(newCount)
        }

        numberInput.value = count.toString()
    }

    const handleAdditionalSignClick = () => {
        inputRef?.current?.focus()
    }

    useEffect(() => {
        setCount(value)
    }, [value])

    return (
        <StyledWrapper>
            <Wrapper display="flex" alignItems="baseline">
                <StyledNumberInput
                    inputRef={inputRef}
                    data-testid="rounded-counter-input"
                    id="rounded-counter"
                    aria-labelledby="rounded-counter-label"
                    value={count}
                    minValue={minValue}
                    maxValue={maxValue}
                    withMaxValueAdditionalSign={withMaxValueAdditionalSign}
                    onChange={handleOnCountChange}
                    onBlur={handleOnCountBlur}
                />
                {withMaxValueAdditionalSign && (
                    <MaxValueAdditionalSign
                        data-testid="rounded-counter-additional-sign"
                        onClick={handleAdditionalSignClick}
                    >
                        {maxValueAdditionalSign}
                    </MaxValueAdditionalSign>
                )}
            </Wrapper>
            <RoundedCounterStyledLabel htmlFor="rounded-counter" id="rounded-counter-label">
                {label}
            </RoundedCounterStyledLabel>
        </StyledWrapper>
    )
}
