import { ApolloLink } from '@apollo/client'
import { RestLink } from 'apollo-link-rest'

import { AuthInput } from '../services/auth/auth.types'

const auth = ({ id, code }: AuthInput, headers: Headers) => {
    const formData = new FormData()

    formData.append('client_id', 'rarebreed')
    formData.append('grant_type', 'onboarding')
    formData.append('username', id)
    formData.append('password', code)
    formData.append('scope', 'onboarding.read onboarding.write')

    return { body: formData, headers }
}

export const restLink = new RestLink({
    uri: process.env.REACT_APP_RG_API_URL ?? 'https://api.rewardgateway.dev',
    bodySerializers: {
        auth,
    },
}) as unknown as ApolloLink
