import styled, { css } from 'styled-components'
import { DisplayProps, SpaceProps, compose, display, space } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { StyledTextButtonProps } from './TextButton.types'

export const StyledTextButton = styled.button<StyledTextButtonProps & SpaceProps & DisplayProps>(
    ({ theme, disabled = false, cursor = 'default' }) => css`
        position: relative;
        color: ${onboardingStyles.colors.lightPurple};
        font-family: GothamRounded;
        font-weight: 600;
        text-decoration: underline;
        opacity: ${disabled ? '0.5' : '1'};
        pointer-events: ${disabled ? 'none' : 'auto'};
        cursor: ${cursor};
        border: none;
        background: transparent;
        display: inline-flex;
        align-items: center;
        font-size: inherit;
        padding: ${theme.space.none};

        ${compose(display, space)};
    `
)

export const LoadingContainer = styled.span`
    position: absolute;
    left: 0;
    right: 0;
`
