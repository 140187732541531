import { t } from '@transifex/native'
import { Billing } from 'hooks/useGetBillingEntity'
import { clearUrlParam, getUrlParams } from 'utils/helpers'

export const DEFAULT_BILLING_COUNTRY: Billing = {
    id: 1,
    country: t('United Kingdom'),
    currency: 'GBP',
    currencySign: '£',
    chargebeeLocale: 'rewardgateway-test',
    phonePrefix: '44',
    requestDemoLink: 'https://www.rewardgateway.com/uk/reward-gateway-for-small-business/request-demo',
}

export type BrowserLanguage = 'en-GB' | 'en-IE' | 'ga-IE' | 'en-AU'
// test if these need to be translated as well
export type Country = 'United Kingdom' | 'Ireland' | 'Australia'
export const languageCountryMap: Record<BrowserLanguage, Country> = {
    'en-GB': 'United Kingdom',
    'en-IE': 'Ireland',
    'ga-IE': 'Ireland',
    'en-AU': 'Australia',
}

export const localeIdToCountryMap: Record<number, Country> = {
    1: 'United Kingdom',
    2: 'Ireland',
    3: 'Australia',
}

export const getBillingById = (billingOptions: Billing[], localeId: number) =>
    billingOptions?.find((option) => option.id === localeId) ?? DEFAULT_BILLING_COUNTRY

export const getBillingByBrowserLanguage = (billingOptions: Billing[]): Billing => {
    const browserLanguage = navigator.language as BrowserLanguage

    return (
        billingOptions?.find((option) => option.country === languageCountryMap[browserLanguage]) ??
        DEFAULT_BILLING_COUNTRY
    )
}

export const doesBillingIdExist = (billingOptions: Billing[], id: number): boolean =>
    billingOptions?.some((option) => option.id === id) ?? false

export const getInitialBilling = (billingOptions: Billing[]): Billing => {
    const localeIdInURL = Number(getUrlParams('locale_id'))
    clearUrlParam('locale_id')
    const localeIdInLocalStorage = Number(localStorage.getItem('billingId'))

    if (localeIdInURL && doesBillingIdExist(billingOptions, localeIdInURL)) {
        return getBillingById(billingOptions, localeIdInURL)
    } else if (localeIdInLocalStorage && doesBillingIdExist(billingOptions, localeIdInLocalStorage)) {
        return getBillingById(billingOptions, localeIdInLocalStorage)
    } else {
        return getBillingByBrowserLanguage(billingOptions)
    }
}
