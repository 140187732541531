import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { StampText } from './components/StampText.styles'

export const DefaultFreeTrialStampText = styled(StampText)(
    ({ theme }) => css`
        padding-top: 10px;

        & span:first-child {
            font-weight: 900;
            font-size: ${theme.fontSizes.xxl};
            line-height: 1.8rem;
            color: ${() => onboardingStyles.colors.darkBlue};
        }

        ${theme.mediaQueries.sm} {
            font-size: ${theme.fontSizes.lg};
            line-height: 1.5rem;

            span:first-child {
                font-size: ${theme.fontSizes.xxl};
                line-height: 2rem;
            }
        }

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.lg};
            line-height: 1.6rem;

            span:first-child {
                font-size: ${theme.fontSizes.xxxl};
                line-height: 2.2rem;
            }
        }
    `
)
