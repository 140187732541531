import styled, { css } from 'styled-components'

export const ProgressBar = styled.progress<{ backgroundColor: string }>(
    ({ theme, backgroundColor }) => css`
        display: block;
        appearance: none;
        border-radius: 4px;
        width: 100%;
        height: 8px;

        ${theme.mediaQueries.md} {
            margin: 0.2rem ${theme.space.none};
        }

        &::-webkit-progress-bar {
            background-color: ${theme.palette.white};
            border-radius: 3px;
        }

        &::-webkit-progress-value {
            background-size:
                35px 20px,
                100% 100%,
                100% 100%;
            background-color: ${backgroundColor};
        }
    `
)

export const ProgressLabel = styled.label(
    ({ theme }) => css`
        font-size: ${theme.fontSizes.sm};
        line-height: 0.5rem;

        ${theme.mediaQueries.sm} {
            font-size: ${theme.fontSizes.md};
        }
    `
)
