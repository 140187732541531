import { useT } from '@transifex/react'

import { Wrapper } from '@rg/patternlab'

import { StampWithRibbon } from './components/StampWithRibbon'
import { DiscountStampText } from './DiscountStamp.styles'

export const DiscountStamp = ({ ribbonText, discount }: { ribbonText?: string; discount: number }) => {
    const t = useT()

    const discountPercentage = `${discount}%`

    return (
        <Wrapper data-testid="discount-stamp">
            <StampWithRibbon {...{ ribbonText }}>
                <DiscountStampText>
                    <span>{t('14 days')}</span>
                    <span>{t('free trial')}</span>
                    <span>{discountPercentage}</span>
                    <span>{t('Discount')}</span>
                </DiscountStampText>
            </StampWithRibbon>
        </Wrapper>
    )
}
