import styled, { css } from 'styled-components'

import { Link } from '@rg/patternlab'

const StyledLink = styled(Link)(
    ({ theme }) => css`
        color: ${theme.palette.info.main['100']};
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
    `
)

type ModalTriggerButtonProps = {
    children: React.ReactNode
    onClick?: () => void
} & React.ComponentProps<typeof StyledLink>

export const ModalTriggerButton = ({ children, onClick, ...rest }: ModalTriggerButtonProps) => {
    return (
        <StyledLink type="button" onClick={onClick} {...rest}>
            {children}
        </StyledLink>
    )
}
