import { LayoutProps, SpaceProps } from 'styled-system'

export enum Size {
    Large = 'lg',
    Medium = 'md',
    Small = 'sm',
}

type StepperCustomProps = {
    font: string
    currentStepSize: string
    currentStepFont: string
    iconSize: number
    labelFontSize: string
}

type StepperStylesProps = SpaceProps & LayoutProps & StepperCustomProps

export type StepperStyles = {
    [key: string]: StepperStylesProps
}

export type Step = {
    route?: string | string[]
    link?: string
    label: string
    automationid: string
}

export type TranslatedStep = Omit<Step, 'label'> & { labelTextString: string }

export type CircleProps = {
    stepperStyles: StepperStyles
    isCurrent?: boolean
    size: Size
}

export type StepperItemProps = {
    isCurrent?: boolean
    size: Size
    label: string
    stepNumber: number
    totalStepsNumber: number
}
