import { useLocation } from 'react-router'

import { ApolloError, QueryHookOptions, useQuery } from '@apollo/client'
import { useBillingContext } from 'context/Billing/BillingContext'
import { getBillingById } from 'context/Billing/billingHelpers'
import { useLanguageContext } from 'context/Language/LanguageContext'

import { Query as GetOnboardingUserQuery } from '@rg/apollo/services/onboarding/onboarding.types'
import { GET_ONBOARDING_USER } from '@rg/apollo/services/onboarding/onboarding.queries'

export const useOnboardingUser = ({
    onCompleted,
    onError,
    options,
}: {
    onCompleted?: (data: GetOnboardingUserQuery) => void
    onError?: (error: ApolloError) => void
    options?: QueryHookOptions
} = {}) => {
    const accessToken = localStorage.getItem('accessToken')
    const onboardingId = localStorage.getItem('onboardingId')
    const { billingOptions, setBilling } = useBillingContext()
    const { language } = useLanguageContext()
    const locationPathname = useLocation().pathname
    // Condition added to use the languageCode from the database on the decision page, as the user's sign-up languageCode is unknown.
    const languageCode = locationPathname === '/decision' ? '' : `?languageCode=${language.code}`

    return useQuery<GetOnboardingUserQuery>(GET_ONBOARDING_USER, {
        ...{
            skip: !accessToken || !onboardingId || !language.code,
            fetchPolicy: 'network-only',
            variables: {
                uuid: onboardingId,
                languageCode,
            },
            ...options,
        },
        onCompleted: (data) => {
            const { localeId } = data?.onboardingUser || {}
            setBilling(getBillingById(billingOptions, localeId))

            if (onCompleted) {
                onCompleted(data)
            }
        },
        onError: (apolloError: ApolloError) => {
            if (onError) {
                onError(apolloError)
            }
        },
    })
}
