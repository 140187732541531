import styled, { css } from 'styled-components'
import { ColorProps, color } from 'styled-system'

import { TD as PatternlabTD, TH as PatternlabTH, TR as PatternlabTR, Table as PatternlabTable } from '@rg/patternlab'

export const TR = styled(PatternlabTR)(
    () => css`
        && {
            display: table-row;
        }
    `
)

export const TH = styled(PatternlabTH)(
    ({ theme }) => css`
        font-size: ${theme.fontSizes.lg};
        color: ${theme.palette.greyscale.contrast['80']};
        background-color: ${theme.palette.greyscale.main['80']};
    `
)

export const TD = styled(PatternlabTD)<ColorProps>(
    ({ theme }) => css`
        && {
            display: table-cell;
            background-color: ${theme.palette.greyscale.main['10']};
            color: ${theme.palette.greyscale.contrast['10']};
            font-size: ${theme.fontSizes.md};
            vertical-align: middle;

            ${color};
        }
    `
)

export const Table = styled(PatternlabTable)`
    ${TR} {
        border-bottom: 1px solid white;

        &:last-child {
            border-bottom: none;
        }

        ${TD}, ${TH} {
            border-right: 1px solid white;

            &:last-child {
                border-right: none;
            }
        }
    }
`
