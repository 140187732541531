import React, { ReactElement, useRef, useState } from 'react'

import { nanoid } from 'nanoid'

import { useClickOutside } from '@rg/patternlab'

import { PopupArrow, PopupArrowShadow, PopupBox, PopupWrapper } from './Popup.styles'
import { PopupProps } from './Popup.types'

export const Popup = ({ children, placement = 'bottom', trigger, zIndex, ...props }: PopupProps): ReactElement => {
    const [isOpen, setIsOpen] = useState(false)
    const { current: id } = useRef(nanoid())
    const { ref } = useClickOutside(() => setIsOpen(false))
    const renderProps = { setIsOpen, isOpen, popupId: id }

    return (
        <PopupWrapper ref={ref}>
            {trigger &&
                React.cloneElement(typeof trigger === 'function' ? trigger(renderProps) : trigger, {
                    onClick: () => setIsOpen(!isOpen),
                    'aria-controls': id,
                })}
            <PopupBox aria-expanded={isOpen} placement={placement} isOpen={isOpen} id={id} zIndex={zIndex} {...props}>
                {typeof children === 'function' ? children(renderProps) : children}
            </PopupBox>
            <PopupArrow placement={placement} zIndex={zIndex} />
            <PopupArrowShadow placement={placement} zIndex={-1} />
        </PopupWrapper>
    )
}

Popup.defaultProps = {
    width: 'auto',
    height: 'auto',
    zIndex: 1,
}
