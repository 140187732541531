import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

import { InputLabel, SelectWrapper, StyledSelect, StyledSelectFloatingLabel } from './Select.styles'
import { SelectFloatingLabelProps, SelectProps } from './Select.types'

// todo: pass Select props down to StyledSelect (react-select) automatically without having to have specify them
export const Select = ({
    disabled = false,
    hasError,
    menuIsOpen,
    name,
    onChange,
    onBlur,
    options,
    placeholder,
    components,
    value,
    ariaLabel,
    getOptionLabel,
    getOptionValue,
    formatOptionLabel,
}: SelectProps) => {
    const handleChange = (value: any) => onChange(name, value)
    const handleBlur = () => onBlur?.(name, true)

    return (
        <SelectWrapper mt="0">
            <StyledSelect
                aria-label={ariaLabel}
                classNamePrefix="rg"
                components={components}
                hasError={hasError}
                isDisabled={disabled}
                menuIsOpen={menuIsOpen}
                onBlur={handleBlur}
                onChange={handleChange}
                options={options}
                placeholder={placeholder}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                formatOptionLabel={formatOptionLabel}
                value={value}
                isSearchable={false}
            />
        </SelectWrapper>
    )
}

// todo: if the value is set programatically, the placeholder should update
export const SelectFloatingLabel = ({
    disabled = false,
    hasError = false,
    label,
    menuIsOpen,
    name,
    onBlur,
    onChange,
    options,
    value = '',
}: SelectFloatingLabelProps) => {
    const [floatLabel, setFloatLabel] = useState(value !== '')
    const handleChange = (value: any) => onChange(name, value)
    const handleFocus = () => {
        setFloatLabel(true)
    }

    const handleBlur = () => {
        if (onBlur) {
            onBlur(name, true)
        }
        if (value === '') {
            setFloatLabel(false)
        }
    }

    const animatedProps = useSpring({
        transform: floatLabel ? 'translate3d(0,-13px,0) scale(0.66)' : 'translate3d(0,0px,0) scale(1)',
        config: {
            tension: 350,
        },
    })

    return (
        <SelectWrapper>
            <InputLabel>
                <animated.div
                    style={Object.assign({}, animatedProps, {
                        transformOrigin: '0%',
                    })}
                >
                    {label}
                </animated.div>
            </InputLabel>
            <StyledSelectFloatingLabel
                classNamePrefix="rg"
                hasError={hasError}
                isDisabled={disabled}
                menuIsOpen={menuIsOpen}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                options={options}
                // placeholder=""
                value={value}
            />
        </SelectWrapper>
    )
}
