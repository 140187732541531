import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { Providers } from 'components/Providers/Providers'

import { App } from './App'

ReactDOM.render(
    <Providers>
        <Router>
            <App />
        </Router>
    </Providers>,
    document.getElementById('root')
)

export const enableHotReload = () => {
    if (module.hot) {
        module.hot.accept()
    } else {
        // eslint-disable-next-line no-console
        console.warn('hot reloading not available')
    }
}

enableHotReload()
