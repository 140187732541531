import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Button, Modal, Wrapper } from '@rg/patternlab'
import { ButtonComponentProps } from '@rg/patternlab/components/Button/Button.types'

export const BillingModalTriggerBtn = styled(Button)<ButtonComponentProps>(
    ({ theme }) => css`
        && {
            border: 1px solid ${theme.palette.greyscale.main['30']};
            background-color: ${theme.palette.greyscale.main['10']};
            color: ${onboardingStyles.colors.darkBlue} !important;
            font-weight: 700;
            border-radius: 0;
            display: flex;
            align-items: center;
            min-width: max-content;

            ${theme.mediaQueries.mdMax} {
                min-width: initial;
            }

            &:hover,
            &:focus,
            &:active {
                border: 1px solid ${onboardingStyles.colors.lighterBlue};
                background-color: ${theme.palette.greyscale.main['20']} !important;
            }

            svg {
                path {
                    fill: ${onboardingStyles.colors.purple};
                }
            }
        }
    `
)

export const BillingSelectWrapper = styled(Wrapper)(
    () => css`
        font-weight: 700;
    `
)

export const StyledModal = styled(Modal)(
    ({ theme }) => css`
        width: 100%;
        min-width: 20%;
        display: flex;
        padding: ${theme.space.md};
        border-radius: 10px;

        div {
            overflow: visible;
        }

        .rg {
            &__menu {
                overflow: auto;
            }
        }
    `
)

export const SelectWrapper = styled(Wrapper)<{ zIndex: number }>`
    position: relative;
    z-index: ${(props) => props.zIndex};
`
