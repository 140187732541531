import styled, { css } from 'styled-components'
import { maxWidth } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Wrapper } from '@rg/patternlab'

import { LogoWrapperProps } from './Header.types'

export const HeaderWrapper = styled.header(
    ({ theme }) => css`
        position: relative;
        width: 100%;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        border-bottom: ${onboardingStyles.colors.lightPurple} solid 4px;
        z-index: 1200;
        background-color: ${theme.palette.white};
        height: ${onboardingStyles.dimensions.topBarHeight.default};

        ${theme.mediaQueries.md} {
            height: ${onboardingStyles.dimensions.topBarHeight.sm};
        }
    `
)

export const LogoWrapper = styled.img<LogoWrapperProps>`
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: max-width 0.2s ease-out;

    ${maxWidth}
`

LogoWrapper.defaultProps = {
    maxWidth: {
        default: `160px`,
        sm: `190px`,
    },
}

export const HeaderInner = styled(Wrapper)(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: ${onboardingStyles.dimensions.topBarHeight.default};

        ${theme.mediaQueries.md} {
            height: ${onboardingStyles.dimensions.topBarHeight.sm};
        }
    `
)

export const InnerWrapper = styled(Wrapper)(
    ({ theme }) => css`
        display: inline-flex;
        justify-content: end;
        gap: ${theme.space.md};
        max-width: 50%;
        flex: 1;
    `
)
