import { ToastContainer, ToastContainerProps } from 'react-toastify'

import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { Wrapper } from 'components/ui/Wrapper/Wrapper'

import { Span } from '@rg/patternlab'

export const StyledBannerNotificationContainer = styled(ToastContainer)<ToastContainerProps>(
    ({ theme }) => css`
        width: 100%;
        z-index: 1000 !important;
        position: relative;
        padding: ${theme.space.none};
        right: 0;

        /* // cancel react-toastify default styles so the toast can be positioned correctly */
        top: 0;
        left: 0;
        margin-left: 0;
        transform: translate(0);

        /* stylelint-disable -- stylelint prefers kebab-case syntax for classnames, however these are coming from external library and we have no control over them */
        .Toastify__toast {
            box-shadow: none;
            color: white;
            min-height: auto;
            margin: ${theme.space.none};
            padding: ${theme.space.none};
            border-radius: 0;
        }

        .Toastify__toast-body {
            margin: ${theme.space.none};
            padding: ${theme.space.none};
            font-family: ${onboardingStyles.font.family};
            text-align: center;

            p {
                margin: ${theme.space.none};
            }
        }
        /* stylelint-enable */
    `
)

// todo: fix glitch (thin white border on left side of close button) when animating
export const NotificationCloseButton = styled.button(
    ({ theme }) => css`
        appearance: none;
        border: none;
        background-color: transparent;
        color: ${theme.palette.white};
        font-size: ${theme.fontSizes.xxl};
        padding: ${theme.space.none} ${theme.space.md};
    `
)

export const NotificationInnerWrapper = styled(Wrapper)(
    ({ theme }) => css`
        display: flex;
        align-items: start;
        justify-content: center;
        flex: 1;
        gap: ${theme.space.sm};
        font-size: 16px;
        font-family: ${onboardingStyles.font.family};
        font-weight: 500;
        padding: ${theme.space.sm};

        ${theme.mediaQueries.md} {
            align-items: center;
        }
    `
)

export const BannerNotificationMessage = styled(Span)(
    ({ theme }) => css`
        && {
            text-align: left;
            color: ${theme.palette.white};
        }
    `
)
