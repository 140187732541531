import { ToastContainer, ToastContainerProps } from 'react-toastify'

import styled, { css } from 'styled-components'

export const NotificationCloseButton = styled.button(
    ({ theme }) => css`
        appearance: none;
        border: none;
        background-color: transparent;
        padding: ${theme.space.md};
        font-size: ${theme.fontSizes.xs};
        position: absolute;
        top: 4px;
        right: 4px;
    `
)

export const StyledToastContainer = styled(ToastContainer)<ToastContainerProps>(
    ({ theme }) => css`
        min-width: 300px;
        padding: ${theme.space.none};
        word-break: break-word;

        /* stylelint-disable -- stylelint prefers kebab-case syntax for classnames, however these are coming from external library and we have no control over them */
        .Toastify__toast {
            color: ${theme.palette.greyscale.main['80']};
            font-family: GothamRounded;
            font-weight: 400;
            padding: ${theme.space.none};
            min-height: auto;
            box-shadow: none;
            overflow: visible;
        }

        .Toastify__toast--default {
            background-color: none;
        }

        ${theme.mediaQueries.smMax} {
            .Toastify__toast {
                max-width: calc(100 % - 24px);
                margin-left: auto;
                margin-right: auto;
            }
        }
        /* stylelint-enable */

        ${theme.mediaQueries.lg} {
            width: 420px;
        }
    `
)
