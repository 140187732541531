import { FC, useMemo } from 'react'

import { RangeInput } from './RangeSlider.styles'
import { StepsLabels } from './StepsLabels'
import { RangeSliderProps } from './RangeSlider.types'
import { getSliderBackgroundSize } from './helpers'

export const RangeSlider: FC<RangeSliderProps> = ({ inputProps, stepsLabels, value, onChange }) => {
    const defaultInputProps = {
        min: 1,
        step: 1,
        max: 100,
    }

    const rangeInputProps = inputProps ?? defaultInputProps
    const { min, max, step } = rangeInputProps

    const defaultStepsLabels = { min, max, mid: max / 2 }
    const labels = stepsLabels ?? defaultStepsLabels

    const sliderBackgroundSize = useMemo(() => getSliderBackgroundSize({ min, max, value }), [min, max, value])

    return (
        <div data-testid="range-slider">
            <StepsLabels {...{ ...labels }} />
            <RangeInput
                id="range-slider"
                type="range"
                data-testid="range-slider-input"
                aria-label="range-slider"
                aria-valuemin={Number(min)}
                aria-valuemax={Number(max)}
                aria-valuenow={value}
                value={value}
                min={min}
                max={max}
                step={step}
                onChange={onChange}
                backgroundSize={sliderBackgroundSize}
            />
        </div>
    )
}
