import styled, { css } from 'styled-components'
import {
    BordersProps,
    ColorProps,
    FlexboxProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    border,
    boxShadow,
    color,
    compose,
    flexbox,
    layout,
    position,
    space,
} from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Span } from '@rg/patternlab'

interface CommonStylingProps
    extends SpaceProps,
        FlexboxProps,
        LayoutProps,
        BordersProps,
        ShadowProps,
        ColorProps,
        PositionProps {}

export const RadioAsButtonGroupContainer = styled.div<CommonStylingProps>(
    ({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: ${theme.space.sm};
        filter: drop-shadow(0 2px 4px rgb(0 0 0 / 15%));
        background-color: ${theme.palette.white};
        border-radius: ${theme.space.lg};

        input {
            display: none;
        }

        label {
            margin: ${theme.space.none};
            color: ${onboardingStyles.colors.purple};
            cursor: pointer;
        }

        ${compose(space, flexbox, layout, border, boxShadow, color, position)}
    `
)

export const RadioAsButtonWrapper = styled.div<CommonStylingProps>`
    ${({ theme }) => css`
        position: relative;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.2s linear;
        display: flex;
        align-items: center;
        border-radius: 32px;
        min-width: 140px;

        ${theme.mediaQueries.lg} {
            padding: ${theme.space.md} ${theme.space.sm};
        }
    `}
    ${compose(space, flexbox, layout, border, boxShadow, color, position)}
`
export const RadioAsButtonFeature = styled(Span)(
    ({ theme }) => css`
        && {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateY(-75%) translateX(-50%);
            max-height: 29px;
            padding: ${theme.space.xxs} ${theme.space.sm};
            border-radius: 5px;
            font-size: ${theme.fontSizes.xs};
            font-weight: ${theme.fontWeights.bold};
            color: ${theme.palette.white};
            background-color: ${theme.palette.primary.main['100']};
            overflow: hidden;
            white-space: nowrap;

            ${theme.mediaQueries.lg} {
                top: 0;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                text-wrap: nowrap;
                max-width: 200px;
                padding: ${theme.space.xs} ${theme.space.md};
            }
        }
    `
)
