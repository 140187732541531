import styled, { css } from 'styled-components'
import { Wrapper } from 'components/ui/Wrapper/Wrapper'

export const PageWrapper = styled(Wrapper)<{ hasPaddingTop: boolean }>(
    ({ theme, hasPaddingTop }) => css`
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: ${hasPaddingTop ? theme.space.lg : theme.space.none};

        ${theme.mediaQueries.md} {
            padding-top: ${hasPaddingTop ? '48px' : theme.space.none};
        }
    `
)
