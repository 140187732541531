import { useEffect, useState } from 'react'

/**
 * Example usage
 *
 * useMedia(`(min-width: ${theme.breakpoints.md})`)
 * useMedia(
 *     `(min-width: ${theme.breakpoints.sm}) and (max-width: ${parseInt(theme.breakpoints.md) - 1}px)`
 * )
 *
 * Notes
 *
 * - For "max-width" queries you need to use parseInt() the breakpoints to remove the 'px' and add it back.
 * To avoid this, we could implement theme.breakpoints.smMax or similar.
 * - For some use cases (no problem rendering the element) you could instead use styled-system 'display' prop and set to 'none'.
 *
 */

export const useMedia = (query: string): boolean => {
    const [matches, setMatches] = useState<boolean>(window.matchMedia(query).matches)

    useEffect(() => {
        const media = window.matchMedia(query)
        setMatches(media.matches)

        const listener = () => setMatches(media.matches)
        media.addListener(listener)
        return () => media.removeListener(listener)
    }, [query])

    return matches
}
