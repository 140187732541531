import styled, { css } from 'styled-components'
import { TypographyProps, typography } from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { Link, Wrapper } from '@rg/patternlab'
import { LinkComponentProps } from '@rg/patternlab/components/Link/Link.types'

import { CircleProps } from './Stepper.types'

export const StepperWrapper = styled.nav(
    ({ theme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin: ${theme.space.sm} ${theme.space.none};
        padding: ${theme.space.none} ${theme.space.sm};

        ${theme.mediaQueries.sm} {
            margin: ${theme.space.md} ${theme.space.none};
            padding: ${theme.space.none} ${theme.space.md};
        }
    `
)

export const Circle = styled(Wrapper)<CircleProps>(
    ({ stepperStyles, size, isCurrent }) => css`
        border-radius: 50%;
        user-select: none;
        text-align: center;
        border: 1px solid ${onboardingStyles.colors.purple};
        font-size: ${isCurrent ? stepperStyles[size].currentStepFont : stepperStyles[size].font};
        font-weight: ${isCurrent ? 600 : 500};
        color: ${isCurrent ? onboardingStyles.colors.yellow : onboardingStyles.colors.purple};
        background-color: ${isCurrent ? onboardingStyles.colors.purple : 'transparent'};
        width: ${isCurrent ? stepperStyles[size].currentStepSize : stepperStyles[size].size};
        height: ${isCurrent ? stepperStyles[size].currentStepSize : stepperStyles[size].size};
        line-height: ${isCurrent ? stepperStyles[size].currentStepSize : stepperStyles[size].size};
    `
)

export const Label = styled(Wrapper)<TypographyProps>`
    color: ${onboardingStyles.colors.purple};
    ${typography};
`

export const StepperLink = styled(Link)<LinkComponentProps>`
    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }
`
