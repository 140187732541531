import { useRouteMatch } from 'react-router-dom'

import badge from 'images/badge.svg'

import { StampImage, StampWrapper } from './Stamp.styles'

export const Stamp: React.FC = ({ children }) => {
    const isPricingPage = useRouteMatch().path.includes('pricing')

    return (
        <StampWrapper
            data-testid="stamp-badge"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            isPricingPage={isPricingPage}
        >
            <StampImage src={badge} alt="" />
            {children}
        </StampWrapper>
    )
}
