import { useT } from '@transifex/react'

import { Wrapper } from '@rg/patternlab'

import { DefaultFreeTrialStampText } from './DefaultFreeTrialStamp.styles'
import { StampWithRibbon } from './components/StampWithRibbon'

export const DefaultFreeTrialStamp = ({ ribbonText }: { ribbonText?: string }) => {
    const t = useT()

    return (
        <Wrapper data-testid="free-trial-stamp">
            <StampWithRibbon {...{ ribbonText }}>
                <DefaultFreeTrialStampText>
                    <span>{t('Free')}</span>
                    <span>{t('14 days')}</span>
                    <span>{t('trial')}</span>
                </DefaultFreeTrialStampText>
            </StampWithRibbon>
        </Wrapper>
    )
}
