import { DefaultTheme } from 'styled-components'

import { Size, StepperStyles } from './Stepper.types'

export const getStepperStyles = (theme: DefaultTheme) => {
    const styles: StepperStyles = {
        [Size.Small]: {
            size: '25px',
            font: theme.fontSizes.sm,
            currentStepSize: '40px',
            currentStepFont: theme.fontSizes.xl,
            mx: theme.space.xxs,
            iconSize: 20,
            labelFontSize: theme.fontSizes.sm,
        },
        [Size.Medium]: {
            size: '27px',
            font: theme.fontSizes.md,
            currentStepSize: '42px',
            currentStepFont: theme.fontSizes.xxl,
            mx: theme.space.xs,
            iconSize: 24,
            labelFontSize: theme.fontSizes.sm,
        },
        [Size.Large]: {
            size: '29px',
            font: theme.fontSizes.lg,
            currentStepSize: '44px',
            currentStepFont: theme.fontSizes.xxxl,
            mx: theme.space.sm,
            iconSize: 30,
            labelFontSize: theme.fontSizes.md,
        },
    }

    return styles
}
