import { useMemo } from 'react'
import { ToastContainerProps, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'
import { IconType } from 'components/ui/Icon/Icon.types'
import { reject } from 'utils/helpers'

import { useTheme } from '@rg/common'
import { H5 } from '@rg/patternlab'

import { Wrapper } from '../Wrapper/Wrapper.styles'
import { Icon } from '../Icon/Icon'
import { P } from '../Typography/Typography'
import { NotificationCloseButton, StyledToastContainer } from './Notification.styles'
import { GetToastProps, NotificationProps, NotifyProps } from './Notification.types'

export const Notification: React.FC<NotificationProps> = ({
    children,
    variant = 'info',
    title,
    closeToast,
    showClose = true,
}) => {
    const theme = useTheme()

    const iconProps: { name: IconType; color: string } = useMemo(() => {
        switch (variant) {
            case 'success':
                return {
                    color: theme.palette.primary.main['100'],

                    name: 'checked',
                }
            case 'error':
                return {
                    color: onboardingStyles.colors.red,
                    name: 'cancel',
                }
            case 'warning':
                return {
                    color: onboardingStyles.colors.yellow,
                    name: 'high_importance',
                }
            default:
                return {
                    color: onboardingStyles.colors.lightBlue,
                    name: 'info',
                }
        }
    }, [variant])

    return (
        <Wrapper
            borderLeft={`5px solid ${iconProps.color}`}
            boxShadow={onboardingStyles.shadows.mainBox}
            display="flex"
            backgroundColor={theme.palette.white}
            mb={theme.space.none}
            p={theme.space.md}
        >
            {showClose && (
                <NotificationCloseButton aria-label="Close notification" onClick={closeToast}>
                    <Icon name="delete" color={theme.palette.greyscale.main['80']} size={12} />
                </NotificationCloseButton>
            )}
            <Icon {...iconProps} marginRight={theme.space.sm} size={26} alignItems="flex-start" />
            <div>
                <H5 pr={theme.space.sm} mb={theme.space.sm}>
                    {title}
                </H5>
                {children}
            </div>
        </Wrapper>
    )
}

export const NotificationContainer: React.FC<ToastContainerProps> = (props) => {
    // Remove option for closeButton and hideProgressBar
    const picked = useMemo(() => reject(props, ['closeButton', 'hideProgressBar']), [props])
    return (
        <StyledToastContainer
            enableMultiContainer
            containerId="toast"
            closeButton={false}
            hideProgressBar
            {...picked}
        />
    )
}

const getToast = ({ title, variant, message, sticky, timeout }: GetToastProps) =>
    toast(
        <Notification title={title} variant={variant}>
            {typeof message === 'string' ? <P>{message}</P> : message}
        </Notification>,
        { autoClose: sticky ? false : timeout, containerId: 'toast' }
    )

export const notify = {
    success: ({ title = 'Success', message, sticky = false, timeout = 5000 }: NotifyProps) =>
        getToast({ variant: 'success', title, message, sticky, timeout }),
    error: ({ title = 'Error', message, sticky = false, timeout = 5000 }: NotifyProps) =>
        getToast({ variant: 'error', title, message, sticky, timeout }),
    info: ({ title = 'Info', message, sticky = false, timeout = 5000 }: NotifyProps) =>
        getToast({ variant: 'info', title, message, sticky, timeout }),
    warning: ({ title = 'Warning', message, sticky = false, timeout = 5000 }: NotifyProps) =>
        getToast({ variant: 'warning', title, message, sticky, timeout }),
}
