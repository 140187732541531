import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { StampText } from './components/StampText.styles'

export const DiscountStampText = styled(StampText)(
    ({ theme }) => css`
        line-height: 1.1rem;

        & span:first-child {
            font-style: normal;
        }

        & span:nth-child(2) {
            font-style: normal;
        }

        & span:last-child {
            margin: ${theme.space.sm} ${theme.space.none} ${theme.space.xs};
            font-weight: 900;
            font-size: ${theme.fontSizes.xxl};
            color: ${() => onboardingStyles.colors.darkBlue};
        }

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.md};

            span:last-child {
                margin: ${theme.space.sm} ${theme.space.none} ${theme.space.xs};
                font-size: ${theme.fontSizes.xl};
            }
        }

        ${theme.mediaQueries.lg} {
            font-size: ${theme.fontSizes.md};

            span:last-child {
                margin: ${theme.space.sm} ${theme.space.none} ${theme.space.xs};
                font-size: ${theme.fontSizes.xl};
            }
        }
    `
)
