import styled, { css } from 'styled-components'
import {
    BorderProps,
    DisplayProps,
    LayoutProps,
    SpaceProps,
    border,
    compose,
    display,
    layout,
    space,
    variant,
} from 'styled-system'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { ButtonWrapperProps } from './Button.types'

export const ButtonWrapper = styled.button<ButtonWrapperProps & BorderProps & DisplayProps & LayoutProps & SpaceProps>(
    ({ theme, block, disabled, center, small }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${onboardingStyles.font.family};
        font-weight: ${small ? '300' : '600'};
        height: 'auto';
        align-self: ${center ? 'center' : 'auto'};
        border-radius: 999px;
        width: ${block ? '100%' : onboardingStyles.dimensions.buttonDefaultWidth};
        max-width: 100%;
        ${variant({
            variants: {
                primary: {
                    color: onboardingStyles.elementColors.primaryBtnTxt,
                    backgroundColor: onboardingStyles.elementColors.primaryBtnBg,
                    borderColor: onboardingStyles.elementColors.primaryBtnBg,
                    '&:hover': {
                        backgroundColor: onboardingStyles.elementColors.primaryBtnHoverBg,
                    },
                },
                secondary: {
                    color: onboardingStyles.elementColors.secondaryBtnTxt,
                    backgroundColor: onboardingStyles.elementColors.secondaryBtnBg,
                    borderColor: onboardingStyles.elementColors.secondaryBtnBg,
                    '&:hover': {
                        backgroundColor: onboardingStyles.elementColors.secondaryBtnHoverBg,
                    },
                },
                outline: {
                    color: onboardingStyles.colors.purple,
                    backgroundColor: 'transparent',
                    borderColor: onboardingStyles.colors.purple,
                    '&:hover': {
                        backgroundColor: theme.palette.greyscale.main['10'],
                    },
                },
                ghost: {
                    color: onboardingStyles.colors.purple,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    '&:hover': {
                        backgroundColor: theme.palette.greyscale.main['10'],
                    },
                },
                grey: {
                    color: theme.palette.greyscale.contrast['80'],
                    backgroundColor: theme.palette.greyscale.main['80'],
                    borderColor: theme.palette.greyscale.main['80'],
                    '&:hover': {
                        backgroundColor: theme.palette.greyscale.main['50'],
                    },
                },
            },
        })}
        cursor: ${disabled ? 'auto' : 'pointer'};
        opacity: ${disabled ? '0.5 ' : 1};
        border-style: solid;
        border-width: 1px;
        transition: background-color 0.15s ease-out;
        padding: ${small ? '6px 20px' : `${theme.space.md} ${theme.space.lg}`};
        font-size: ${small ? theme.fontSizes.sm : theme.fontSizes.lg};
        margin-top: 25px;

        ${theme.mediaQueries.sm} {
            margin-top: 40px;
        }

        &:focus {
            border: 1px solid ${onboardingStyles.colors.lightBlue};
            box-shadow: ${onboardingStyles.shadows.focusBox};
            outline: none;
        }

        && {
            ${compose(border, display, layout, space)}
        }
    `
)
