import { gql } from '@apollo/client'

const Query = gql`
    type Query {
        _blank: String
    }
`

const Mutation = gql`
    type Mutation {
        _blank: String
    }
`

export const typeDefs = [Query, Mutation]
