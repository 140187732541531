import { OnboardingUser } from '@rg/apollo/services/onboarding/onboarding.types'
import { Referral } from '@rg/apollo/services/referrals/referrals.types'

export function skipAllowed(
    loadingUserData: boolean,
    loadingReferralData: boolean,
    onboardingUser?: OnboardingUser,
    referral?: Referral
): boolean | undefined {
    const isFreshProspect = loadingUserData === false && onboardingUser === undefined
    const noReferral = !referral && !loadingReferralData

    if (isFreshProspect && noReferral) return true

    return (
        Boolean(onboardingUser?.skipAllowed) && (!onboardingUser?.referralCode ? true : Boolean(referral?.skipAllowed))
    )
}
