import { Language } from './language.types'
import { DEFAULT_TX_LANGUAGE } from './LanguageContext'

const recaptchaRegionalLanguages = [
    'zh-HK', // Chinese (Hong Kong)
    'zh-CN', // Chinese (Simplified)
    'zh-TW', // Chinese (Traditional)
    'en-GB', // English (UK)
    'fr-CA', // French (Canadian)
    'de-AT', // German (Austria)
    'de-CH', // German (Switzerland)
    'pt-BR', // Portuguese (Brazil)
    'pt-PT', // Portuguese (Portugal)
    'es-419', // Spanish (Latin America)
]

export const getRecaptchaLanguage = (language: string): string => {
    const formattedLanguage = language.replace(/_/g, '-')
    const isRegionalLanguageSupported = recaptchaRegionalLanguages.includes(formattedLanguage)

    return isRegionalLanguageSupported ? formattedLanguage : formattedLanguage.replace(/-.*/, '')
}

export const getInitialLanguage = (languages: Language[]) => {
    const languageAndCodeMatch = findByBrowserLanguageAndCountryCode(languages)
    if (languageAndCodeMatch) return languageAndCodeMatch

    const languageMatch = findByBrowserLanguageCode(languages)
    if (languageMatch) return languageMatch

    return DEFAULT_TX_LANGUAGE
}

export const findByBrowserLanguageAndCountryCode = (languages: Language[]) => {
    const browserLanguage = navigator.language.replace(/-/g, '_')
    return languages.find((l) => l.code === browserLanguage)
}

export const findByBrowserLanguageCode = (languages: Language[]) => {
    const browserLanguageCode = navigator.language.split('-')[0]
    return languages.find((l) => l.code.includes(browserLanguageCode))
}

export const findLanguageByCode = (languages: Language[], code: string): Language => {
    return languages.find((l) => l.code === code) ?? DEFAULT_TX_LANGUAGE
}
