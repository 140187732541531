import styled, { css } from 'styled-components'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

import { StampText } from './components/StampText.styles'

export const ExtendedFreetrialStampText = styled(StampText)(
    ({ theme }) => css`
        padding-top: 10px;

        & span:nth-child(2) {
            font-weight: 900;
            color: ${() => onboardingStyles.colors.darkBlue};
            font-size: ${theme.fontSizes.xxl};
        }

        ${theme.mediaQueries.md} {
            font-size: ${theme.fontSizes.lg};
            line-height: 1.5rem;
        }

        ${theme.mediaQueries.lg} {
            line-height: 1.6rem;
        }
    `
)
