import { useEffect, useState } from 'react'

import { sanitize } from 'dompurify'

import { StyledNumberInput } from './NumberInput.styles'
import { NumberInputProps } from './NumberInput.types'

export const NumberInput: React.FC<NumberInputProps> = ({
    value,
    onChange,
    onBlur,
    minValue,
    maxValue,
    inputRef,
    ...props
}) => {
    const [inputValue, setInputValue] = useState<number>(value)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(parseInt(sanitize(e.target.value)))
        if (onChange) {
            onChange(e)
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'e' || e.key === '.' || e.key === ',' || e.key === '+' || e.key === '-') e.preventDefault()
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (minValue !== undefined && inputValue < minValue) {
            if (onChange) {
                onChange(e)
            }
            setInputValue(minValue)
        }
        if (maxValue !== undefined && inputValue > maxValue) {
            if (onChange) {
                onChange(e)
            }
            setInputValue(maxValue)
        }
        if (!e.target.value) {
            setInputValue(value)
        }

        if (onBlur) {
            onBlur(e)
        }
    }

    useEffect(() => {
        setInputValue(parseInt(sanitize(String(value))))
    }, [value])

    return (
        <StyledNumberInput
            {...props}
            {...(inputRef && { ref: inputRef })}
            type="number"
            step="1"
            min={minValue}
            max={maxValue}
            value={Number.isNaN(inputValue) ? '' : inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    )
}
