import styled from 'styled-components'
import {
    BorderProps,
    ColorProps,
    DisplayProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    TextAlignProps,
    TypographyProps,
    border,
    color,
    compose,
    display,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    textAlign,
    typography,
} from 'styled-system'

/**
 * A utility styled component to help control layout
 */
export const Wrapper = styled.div<
    ColorProps &
        DisplayProps &
        SpaceProps &
        PositionProps &
        LayoutProps &
        FlexboxProps &
        TextAlignProps &
        GridProps &
        TypographyProps &
        ShadowProps &
        BorderProps
>`
    ${compose(color, display, flexbox, layout, position, shadow, space, textAlign, grid, typography, border)}
`
