import { createGlobalStyle, css } from 'styled-components'
import reset from 'styled-reset'
import { onboardingStyles } from 'styles/onboardingStyles/onboardingStyles'

/*
    This file is not autoformatted on save by Prettier 😞
    More info: https://github.com/prettier/prettier/issues/4260
*/

const fontPath = 'fonts/gotham_rounded/'

export const GlobalStyle = createGlobalStyle(
    ({ theme }) => css`
        /* the following line will run even if commented */
        ${reset}

        /* GothamRoundedLight */
  @font-face {
            font-family: ${onboardingStyles.font.family};
            font-style: normal;
            font-weight: 300;
            src: url('${fontPath}gothamrnd-light.eot');
            src:
                url('${fontPath}gothamrnd-light.eot') format('embedded-opentype'),
                url('${fontPath}gothamrnd-light.woff') format('woff'),
                url('${fontPath}gothamrnd-light.ttf') format('truetype'),
                url('${fontPath}gothamrnd-light.svg#GothamRoundedLight') format('svg');
        }

        /* GothamRoundedBook */
        @font-face {
            font-family: ${onboardingStyles.font.family};
            font-style: normal;
            font-weight: 400;
            src: url('${fontPath}gothamrnd-book.eot');
            src:
                url('${fontPath}gothamrnd-book.eot') format('embedded-opentype'),
                url('${fontPath}gothamrnd-book.woff') format('woff'),
                url('${fontPath}gothamrnd-book.ttf') format('truetype'),
                url('${fontPath}gothamrnd-book.svg#GothamRoundedBook') format('svg');
        }

        /* GothamRoundedMedium */
        @font-face {
            font-family: ${onboardingStyles.font.family};
            font-style: normal;
            font-weight: 600;
            src: url('${fontPath}gothamrnd-medium.eot');
            src:
                url('${fontPath}gothamrnd-medium.eot') format('embedded-opentype'),
                url('${fontPath}gothamrnd-medium.woff') format('woff'),
                url('${fontPath}gothamrnd-medium.ttf') format('truetype'),
                url('${fontPath}gothamrnd-medium.svg#GothamRoundedMedium') format('svg');
        }

        /* GothamRoundedBold */
        @font-face {
            font-family: ${onboardingStyles.font.family};
            font-style: normal;
            font-weight: 700;
            src: url('${fontPath}gothamrnd-bold.eot');
            src:
                url('${fontPath}gothamrnd-bold.eot') format('embedded-opentype'),
                url('${fontPath}gothamrnd-bold.woff') format('woff'),
                url('${fontPath}gothamrnd-bold.ttf') format('truetype'),
                url('${fontPath}gothamrnd-bold.svg#GothamRoundedBold') format('svg');
        }

        *,
        *::before,
        *::after {
            /* Required to enforce the Onboarding App font-family upon all local and patternlab components */
            font-family: ${`${onboardingStyles.font.family}, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`} !important;
        }

        html,
        body,
        #root {
            /* need these to allow sticking elements to bottom of page */
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        html {
            font-weight: 400;
            width: 100%;
        }

        body {
            color: ${onboardingStyles.elementColors.bodyTxt};
            min-width: ${onboardingStyles.dimensions.bodyMinWidth};
            font-size: 14px;
            line-height: 1.5;
            background-color: ${theme.palette.greyscale.main['10']};

            /* don't use min height: 100vh or content will be taller than viewport on mobile */

            /* height: 100%; this makes scrolling rough and buggy on mobile */

            ${theme.mediaQueries.sm} {
                font-size: 15px;
            }

            ${theme.mediaQueries.md} {
                font-size: 16px;
            }
        }

        body,
        #root {
            display: flex;
            flex-direction: column;
        }

        .content {
            flex-grow: 1;
        }

        * {
            box-sizing: border-box;
        }

        p {
            margin: 0 0 15px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.4;
        }

        strong {
            font-weight: 700;
        }

        small {
            font-size: 13px;

            ${theme.mediaQueries.md} {
                font-size: 14px;
            }
        }

        a {
            font-weight: 600;
            color: ${onboardingStyles.colors.lightPurple};

            &:hover,
            &:focus {
                color: ${onboardingStyles.elementColors.linkHover};
            }
        }

        svg {
            max-width: 100%;
            max-height: 100%;
        }

        ul {
            display: block;
            list-style-type: disc;
            padding-left: 15px;

            li {
                margin-bottom: 10px;
            }
        }

        hr {
            border-style: solid;
            margin-top: 16px;
            margin-bottom: 16px;
            border-top-color: ${theme.palette.greyscale.main['30']};
            border-bottom-width: 0;
            width: 100%;
        }

        img {
            display: block;
        }

        .bg-dark-gray {
            background-color: #666;
        }

        .bg-gray {
            background-color: #d9d9d9;
        }

        .bg-light-gray {
            background-color: #f2f2f2;
        }

        .text-white {
            color: #fff;
        }

        .text-black {
            color: #333;
        }

        .p-md {
            padding: 16px;
        }

        .border-white {
            border: 1px solid #fff;
        }

        .valign-middle {
            vertical-align: middle;
        }
    `
)
