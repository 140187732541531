import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { globalAny } from 'index.constants'

import { ChargebeeModalProps, ChargebeeRefObject } from './ChargebeeModal.types'

const dropInScriptUrl = 'https://js.chargebee.com/v2/chargebee.js'

// eslint-disable-next-line react/display-name
export const ChargebeeModal = forwardRef<ChargebeeRefObject, ChargebeeModalProps>(({ chargebeeSiteId }, ref) => {
    const [cbInstance, setCbInstance] = useState()

    const loadChargebee = () => {
        const chargebee = globalAny.window.Chargebee
        const chargebeeInstance = chargebee.init({ site: chargebeeSiteId })
        chargebee.registerAgain()

        setCbInstance(chargebeeInstance)
    }

    useEffect(() => {
        const chargebee = globalAny.window.Chargebee

        if (chargebee) {
            setCbInstance(chargebee.getInstance())
            return
        }

        const script = document.createElement('script')

        script.src = dropInScriptUrl
        script.onload = loadChargebee

        document.body.appendChild(script)

        // eslint-disable-next-line
    }, [])

    useImperativeHandle(
        ref,
        () => ({
            openCheckout: (cbInstance as any)?.openCheckout,
        }),
        [cbInstance]
    )

    return null
})
