import { DisplayProps, FlexboxProps, LayoutProps, SpaceProps } from 'styled-system'

import { iconPaths } from './Icon.svgs'
import { IconProps, IconType } from './Icon.types'
import { IconWrapper } from './Icon.styles'

export const Icon = ({
    name,
    color = 'black',
    size = 30,
    viewBoxSize = 16,
    ...props
}: IconProps & DisplayProps & FlexboxProps & LayoutProps & SpaceProps) => (
    <IconWrapper {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
            strokeWidth="0.3"
            strokeLinecap="square"
            strokeLinejoin="miter"
            stroke={color}
            fill={color}
            width={size}
            height={size}
            overflow="visible"
            data-testid={`icon-${name}`}
        >
            <path d={iconPaths[name as IconType]} />
        </svg>
    </IconWrapper>
)
